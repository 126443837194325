import React, { MouseEventHandler, useState, useContext } from 'react';
import { useQuestion } from '../../../hooks/question';
import { useQuestionConfigValues } from '../../../hooks/questionConfigValue';
import Loading from '../../Shared/ResourceIndex/Loading';
import QuestionDetailForm from './Forms/QuestionDetailForm';
import InspectionQuestionContext from '../../../contexts/InspectionQuestionContext';
import { Question } from '@sw-sw/lib-inspection-templates';

export interface IEditQuestionProps {
  questionId: number;
  onCancel: MouseEventHandler;
}

const EditQuestion: React.FC<IEditQuestionProps> = ({
  questionId,
  onCancel,
}) => {
  const inspectionQuestionContext = useContext(InspectionQuestionContext);
  const questionQuery = useQuestion(questionId);
  const questionValuesQuery = useQuestionConfigValues(questionId);
  const [loading, setLoading] = useState<boolean>(false);

  if (
    questionQuery.isLoading ||
    questionValuesQuery.isLoading ||
    !questionQuery.data
  ) {
    return <Loading />;
  }

  const handleSubmit = async (question: Question & { id: number }) => {

    setLoading(true);

    try {
      await inspectionQuestionContext.updateQuestion(question);
      await questionQuery.refetch();
      await questionValuesQuery.refetch().then((e: any) => onCancel(e));
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <QuestionDetailForm
      question={questionQuery.data}
      questionValues={questionValuesQuery.data}
      onCancel={onCancel}
      onSubmit={handleSubmit}
      order={questionQuery.data.sort_order}
    />
  );
};

export default EditQuestion;
