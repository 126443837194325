import { FormContext, FormSchemaFields } from '@sw-sw/lib-form';
import { UIControlType } from '@sw-sw/lib-form-control-types';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useCallback } from 'react';
import AddUsersInput from '../AddUser/AddUsersInput';
import userApi from '../../../utils/api/user';
import { get, uniqBy } from 'lodash';
import divisionApi from '../../../utils/api/division';
import tenantApi from '../../../utils/api/tenant';

const roleNameToManagerTitle = {
  Inspector: 'Managers',
  'Area / Assistant Manager': 'Regional Manager',
};

const getProjectOptions = (availableClients, selectedClients) => {
  availableClients.sort((a, b) => a.name.localeCompare(b.name));
  if (selectedClients) {
    const selectedClientIds = selectedClients.map((client) => client.id);
    const clients = availableClients.filter((ac) =>
      selectedClientIds.includes(ac.id),
    );

    return clients.reduce((acc, client) => acc.concat(client.projects), []);
  }

  return [];
};

const getSchema = (formData, isEdit) => {
  const formContext = useContext(FormContext);

  const selectedRole = formData.roles.find(
    (v) => v.id === formContext.value.roleId,
  );

  let roles = formData.roles.sort((a, b) => a.name.localeCompare(b.name));

  const divisions = formData.divisions;
  const allDivisions = formData.allDivisions;
  const tenants = formData.tenants;
  const selectedRole1 = selectedRole
    ? selectedRole.name
    : formContext.initialValue.roleName
      ? formContext.initialValue.roleName
      : null;

  const clients = formData.clients;
  const managers = formData.managers;

  const sch = {
    tags: {
      label: 'Email',
      controlType: UIControlType.custom,
      validation: {
        required: true,
      },
      renderControl: AddUsersInput,
    },
    roleId: {
      label: 'Role',
      controlType: UIControlType.select,
      options: roles,
      labelKey: 'name',
      valueKey: 'id',
      validation: {
        required: true,
      },
      parse: (val) => Number.parseInt(val, 10),
    },
    tenantId: {
      label: 'Tenant',
      controlType: UIControlType.select,
      options: tenants,
      labelKey: 'name',
      valueKey: 'id',
      validation: {
        required: true,
      },
      parse: (val) => Number.parseInt(val, 10),
    },
    divisionIds: {
      controlType: UIControlType.customTagSelect,
      labelKey: 'name',
      valueKey: 'id',
      isMulti: true,
      openOnFocus: true,
      label: 'Divisions',
      options: divisions,
      noOptionMessage: 'No Divisions found',
      placeholder: 'Search for Divisions',
      validation: {
        required: true,
      },
    },
  };

  sch.clients = {
    controlType: UIControlType.customTagSelect,
    labelKey: 'name',
    valueKey: 'id',
    isMulti: true,
    openOnFocus: true,
    label: 'Clients',
    options: clients,
    noOptionMessage: 'No clients found',
    placeholder: 'Search for clients',
    showClearAll: true,
  };

  sch.projects = {
    controlType: UIControlType.customTagSelect,
    labelKey: 'name',
    valueKey: 'id',
    isMulti: true,
    openOnFocus: true,
    label: 'Projects',
    options:
      (clients && getProjectOptions(clients, formContext.value.clients)) || [],
    noOptionMessage: 'No projects found',
    placeholder: 'Search for projects',
    menuPlacement: 'top',
    showSelectAll: true,
    showClearAll: true,
  };

  if (selectedRole1 && roleNameToManagerTitle[selectedRole1]) {
    sch.managerUserIds = {
      label: `Assign to ${roleNameToManagerTitle[selectedRole1]}`,
      controlType: UIControlType.customTagSelect,
      options: managers,
      isMulti: true,
      openOnFocus: true,
      style: {
        flex: 1,
      },
      labelKey: 'name',
      valueKey: 'id',
    };
  }

  if (isEdit) {
    delete sch.tags;
  }

  if (selectedRole1 === 'Super Admin') {
    sch.tenantIds = {
      controlType: UIControlType.customTagSelect,
      labelKey: 'name',
      valueKey: 'id',
      isMulti: true,
      openOnFocus: true,
      label: 'Tenant',
      options: tenants,
      noOptionMessage: 'No Tenant found',
      placeholder: 'Search for Tenant',
      showSelectAll: true,
      showClearAll: true,
      validation: {
        required: true,
      },
    };

    delete sch.tenantId;
    delete sch.divisionIds;
    delete sch.clients;
    delete sch.projects;

    sch.allDivisionsIds = {
      controlType: UIControlType.customTagSelect,
      labelKey: 'name',
      valueKey: 'id',
      isMulti: true,
      openOnFocus: true,
      label: 'Divisions',
      options: allDivisions,
      noOptionMessage: 'No Divisions found',
      placeholder: 'Search for Divisions',
      showSelectAll: true,
      showClearAll: true,
      validation: {
        required: true,
      },
    };
  }

  return sch;
};

const AddUser = ({ formOpts, setOptionsData, className, isEdit }) => {
  const formContext = useContext(FormContext);
  const [formData, setFormData] = React.useState(formOpts);

  const setDivisions = useCallback(
    (tenantId) => {
      tenantApi.divisions
        .index(tenantId)
        .then((data) => {
          setFormData({
            ...formData,
            divisions: data.length
              ? data //uniqBy(data, ...get(formContext, 'value.divisionIds', []), 'id')
              : [],
          });

          if (
            formContext.value.divisionIds &&
            formContext.value.divisionIds.length
          ) {
            formContext.set(
              'divisionIds',
              formContext.value.divisionIds.filter((c) =>
                data.map((_) => _.id).includes(c.id),
              ),
            );
          } else {
            formContext.set('divisionIds', []);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [formContext.value.tenantId],
  );

  const setClients = useCallback(async () => {
    if (formContext.value.divisionIds.length) {
      const clientIds = await (formContext.value.divisionIds.length
        ? Promise.all(
          formContext.value.divisionIds.map(async (division) => {
            const clients = await divisionApi.clients.index(division.id);
            const ids = clients.map((client) => client.id);

            return [...ids];
          }),
        )
        : Promise.resolve([]));

      const divisionIds = formContext.value.divisionIds
        ? formContext.value.divisionIds.map((_) => _.id)
        : [];

      return userApi
        .getCreateOpts({
          divisionIds: divisionIds,
          roleId: formContext.value.roleId,
          clientIds: clientIds,
          tenantId: formContext.value.tenantId,
        })
        .then((data) => {
          if (formContext.value.clients) {
            formContext.set(
              'clients',
              formContext.value.clients.filter((c) =>
                get(data, 'clients')
                  .map((_) => _.id)
                  .includes(c.id),
              ),
            );
          }
          // combine server options with current user data
          // in case the user is associated with other data
          setFormData({
            ...formData,
            managers: data.managers,
            clients: data.clients.length
              ? uniqBy(
                [
                  ...get(data, 'clients', []),
                ],
                'id',
              )
              : [],
          });

          formContext.setBusy(false);
        });
    }
  }, [formContext.value.divisionIds, formContext.value.roleId]);

  useEffect(() => {
    if (formContext.value.tenantId) {
      setDivisions(formContext.value.tenantId);
    }
  }, [formContext.value.tenantId]);

  useEffect(() => {
    if (formContext.value.tenantIds && formContext.value.tenantIds.length) {
      const tenantIds = formContext.value.tenantIds
        ? formContext.value.tenantIds.map((_) => _.id)
        : [];

      tenantApi.divisions.getDivisionsForTenants(tenantIds).then((data) => {
        setFormData({
          ...formData,
          allDivisions: data,
        });
      });
    }
  }, [formContext.value.tenantIds]);

  useEffect(() => {
    if (formContext.value.divisionIds && formContext.value.roleId) {
      setClients();
    }
  }, [formContext.value.divisionIds, formContext.value.roleId]);

  return (
    <FormSchemaFields
      className={className || 'findings-form'}
      schema={getSchema(formData, isEdit)}
      formData={formContext.value}
      onChange={formContext.replace}
    />
  );
};

const optionShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
});

AddUser.propTypes = {
  formOpts: PropTypes.shape({
    roles: PropTypes.arrayOf(optionShape).isRequired,
    divisions: PropTypes.arrayOf(optionShape),
  }).isRequired,
};

AddUser.defaultProps = {
  className: null,
  formOpts: {
    tags: [],
    divisions: [],
    roles: [],
    tenants: [],
  },
  isEdit: false,
};

export default AddUser;
