import { InspectionTemplate } from '@sw-sw/lib-inspection-templates';
import { find, get } from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { QueryObserverResult, useQuery } from 'react-query';
import { useRouteMatch } from 'react-router-dom';
import AppDivisionContext from '../contexts/AppDivisionContext';
import {
  customPrintInspections,
  InspectionWithFindingCount,
  useProjectInspections
} from "../hooks/inspection";
import divisionApi from "../utils/api/division";
import inspectionApi from "../utils/api/inspection";
import projectApi, { Project } from "../utils/api/project";
import { ProjectInspection } from "../utils/api/projectInspection";
import DashboardContext from '../contexts/DashboardContext'
import weatherApi from '../utils/api/weather';

type ProjectDocs = any;

export type ProjectContextValue = {
  project: Project | null;
  projectDocs: ProjectDocs | null;
  projectName: string | null;
  clientName: string | null;
  setProject: (input: Project | null) => void;
  setProjectDocs: (input: ProjectDocs | null) => void;
  updateProjectDocs: (patch: any) => void;
  clear: Dispatch<void>;
  template?: InspectionTemplate | null;
  setTemplate: Dispatch<SetStateAction<InspectionTemplate | null | undefined>>;
  loadDocs: (id: number) => void;
  inspectionsQuery: QueryObserverResult<InspectionWithFindingCount[]>;
  inspections: any[];
  nextInspectionDate: string | null;
  nextInspectionDueToday: boolean;
  inspectionDueToday: () => ProjectInspection | null;
  startRoutineInspection: () => Promise<any>;
  setNextInspectionDueToday: Dispatch<SetStateAction<boolean>>;
  handleStormSelect: (id: number, total: number) => void;
  selectedProjects: Array<number>;
  handleSelectAll: (ids: Array<number>) => void;
  allSelected: boolean;
  handleCancelBulkStorm: Dispatch<void>;
  newDocTypes: Array<any>;
  setNewDocTypes: Function;
  isInspectionHeaderLoader: boolean;
  setIsInspectionHeaderLoader: Function;
  errorMsg: string;
  setErrorMsg: Dispatch<SetStateAction<string>>;
  setInspections: Dispatch<any>;
  getCustomPrint?: any;
  document_date?: any,
  setDocumentDate: Dispatch<React.SetStateAction<undefined>>
  filterProjectType?: any
  setFilterProjectType?: (input: any) => void
  filterProjectPrimaryContact?: any
  setFilterProjectPrimaryContact?: (input: any) => void,
  weather: any,
  location: any,
  loaded: boolean,
  error: boolean,
  fetchWeatherData: () => void,
};

const Context = React.createContext<ProjectContextValue>(null as any);

export const ProjectProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const routeMatch = useRouteMatch();
  const appDivisionContext = useContext(AppDivisionContext);
  const { fetchStatsFuncForID, getTeamManagementData } =
    useContext(DashboardContext);
  const divisionId = get(
    routeMatch.params,
    'division_id',
    appDivisionContext.appDivisionId,
  );
  const templateQuery = useQuery({
    queryFn: () =>
      divisionId
        ? divisionApi.inspectionTemplates.index(divisionId)
        : Promise.resolve([]),
    queryKey: ['divisionInspectionTemplates', divisionId],
    refetchOnWindowFocus: false,
  });
  const isMap = window.location.href.split('/').includes('map')
  const pageString = Number(window.location.search.split('=')[1])
  const [project, setProject] = useState<Project | null>(null);
  const [errorMsg, setErrorMsg] = useState('');
  const [projectDocs, setProjectDocs] = useState<ProjectDocs | null>(null);
  const [template, setTemplate] = useState<
    InspectionTemplate | null | undefined
  >(null);
  const [selectedProjects, setSelectedProjects] = useState<Array<number>>([]);
  const [allSelected, setAllSelected] = useState(false);
  const [isInspectionHeaderLoader, setIsInspectionHeaderLoader] = useState(false);
  const inspectionsQuery = useProjectInspections( project ? project.id : 0, undefined, pageString? pageString : 1, isMap ? undefined : 1)
  const getCustomPrint = customPrintInspections(project ? project.id : 0)
  const [document_date, setDocumentDate] = useState()
  const [inspections, setInspections] = useState(inspectionsQuery.data || []);
  const [filterProjectType, setFilterProjectType] = useState([]);
  const [filterProjectPrimaryContact, setFilterProjectPrimaryContact] = useState([]);

  const [dueTodayInspectionId, setDueTodayInspectionId] = useState<
    number | null
  >(null);
  const nextInspectionDate =
    project && project.next_inspection_date
      ? project.next_inspection_date
      : null;

  const [nextInspectionDueToday, setNextInspectionDueToday] =
    useState<boolean>(false);

  const [newDocTypes, setNewDocTypes] = useState([]);
  const [loaded, setLoadedStatus] = useState(false);
  const [error, setErrorStatus] = useState(false);
  const [weather, setWeather] = useState([]);
  const [location, setLocation] = useState({
    latitude: 0,
    longitude: 0,
    denied: false,
  });

  const handleStormSelect = (id: number, total: number) => {
    const newState: Array<number> = [...selectedProjects];

    if (newState.includes(id)) {
      const filterState: Array<number> = newState.filter((item) => item !== id);

      setSelectedProjects(filterState);

      if (allSelected) {
        setAllSelected(false);
      }
    } else {
      newState.push(id);
      setAllSelected(newState.length === total);
      setSelectedProjects(newState);
    }
  };

  const handleSelectAll = (ids: Array<number>) => {
    if (allSelected) {
      setAllSelected(false);
      setSelectedProjects([]);
    } else {
      setAllSelected(true);
      setSelectedProjects(ids);
    }
  };

  const handleCancelBulkStorm = () => {
    setAllSelected(false);
    setSelectedProjects([]);
  };

  const inspectionDueToday = () => {
    if (dueTodayInspectionId && inspections.length) {
      const i = inspections.filter(({ id }) => id === dueTodayInspectionId);

      if (i.length) {
        return i[0];
      }
    }

    // create "virtual" inspection
    if (nextInspectionDueToday && nextInspectionDate) {
      return {
        id: -1,
        status: 'new',
      } as ProjectInspection;
    }

    return null;
  };

  const startRoutineInspection = useCallback(() => {
    if (project) {
      if (dueTodayInspectionId) {
        return Promise.resolve(dueTodayInspectionId);
      } else {
        // new inspection and return id
        return inspectionApi
          .create(project.id, 'Routine')
          .then((i) => {
            getTeamManagementData.refetch();
            fetchStatsFuncForID.refetch();

            return i.id;
          })
          .catch((e) => {
            return setErrorMsg(e.response.data.message);
          });
      }
    }
    
    return Promise.resolve();
  }, [project]);

  const clear = () => {
    setProject(null);
    setProjectDocs(null);
    setTemplate(null);
    setDueTodayInspectionId(null);
    setNextInspectionDueToday(false);
    setSelectedProjects([]);
    setAllSelected(false);
  };

  const loadDocs = async (id: number) => {
    const data = await projectApi.getDocs(id);

    setProjectDocs(data);
  };

  const updateProjectDocs = (patch: any) => {
    setProjectDocs(Object.assign({}, projectDocs, patch));
  };

  function fetchWeatherData() {
    if (project){
      setLoadedStatus(true);

      const weatherData = weatherApi.indexByProjectId(project.id);

      if (weatherData.lenght > 3 && weatherData.name !== "Error") {
        setWeather(weatherData);
      } else {

        const latitude = Number(project.address?.latitude);
        const longitude = Number(project.address?.longitude);

        if (latitude && longitude) {
          setLocation({ latitude , longitude, denied: false });

          weatherApi.fetchByProjectId(latitude, longitude, project.id).then(data => {
            const hasError = data.name === "Error";

            setErrorStatus(hasError);
            setWeather(hasError ? [] : data);
          }).catch(() => {
            setErrorStatus(true);
            setWeather([]);
          });
      }
      }
    }
  }

  useEffect(() => {
    if (
      project &&
      project.id &&
      project.inspection_template_id &&
      templateQuery.isFetched &&
      templateQuery.data
    ) {
      setTemplate(
        templateQuery.data.find(
          (tmpl) => tmpl.id === project.inspection_template_id,
        ),
      );
    }
  }, [project, templateQuery.isFetched]);

  useEffect(() => {
    if (
      inspectionsQuery.isFetched &&
      project &&
      project.timezone &&
      project.next_inspection_date
    ) {
      const today = moment().millisecond(0).second(0).minute(0).hour(0);
      const isInspectionDueToday = find(
        inspectionsQuery.data || [],
        (inspection: any) => {
          return (
            inspection.type === 'Routine' &&
            today.isSame(moment.tz(inspection.created_date, project.timezone))
          );
        },
      );

      setNextInspectionDueToday(
        today.isSame(moment(project.next_inspection_date)),
      );

      if (isInspectionDueToday && isInspectionDueToday.id) {
        setDueTodayInspectionId(isInspectionDueToday.id);
      }
    }
  }, [inspectionsQuery.isFetched, project, pageString]);

  return (
    <Context.Provider
      value={{
        project,
        projectName: project ? project.name.trim() : null,
        clientName: project && project.client ? project.client.name : null,
        setProject,
        projectDocs,
        setProjectDocs,
        updateProjectDocs,
        template,
        setTemplate,
        selectedProjects,
        allSelected,
        inspectionsQuery,
        inspections,
        nextInspectionDate,
        nextInspectionDueToday,
        setNextInspectionDueToday,
        handleStormSelect,
        handleSelectAll,
        handleCancelBulkStorm,
        inspectionDueToday,
        startRoutineInspection,
        clear,
        loadDocs,
        newDocTypes,
        setNewDocTypes,
        isInspectionHeaderLoader,
        setIsInspectionHeaderLoader,
        errorMsg,
        setErrorMsg,
        setInspections,
        getCustomPrint,
        document_date,
        setDocumentDate,
        filterProjectType,
        setFilterProjectType,
        filterProjectPrimaryContact,
        setFilterProjectPrimaryContact,
        weather,
        location,
        loaded,
        error,
        fetchWeatherData,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Context;
export const ProjectContext = Context;
