import React, { useContext, useEffect, useState } from 'react';
import ResourceDetailTabs from '../Shared/ResourceDetail/ResourceDetailTabs';
import { FormContextProvider } from '@sw-sw/lib-form';
import { SettingsTab } from './SettingTabs';
import InspectionIntervals from './InspectionIntervals';
import { RolesContext } from '../../contexts/RolesContext';
import { ProjectTypes } from './ProjectType';
import { RegionComponent } from './Region';
import { AllFeatures } from './Features/AllFeatures';
import UploadCompanyLogo from './UploadCompanyLogo';
import tenantApi from '../../utils/api/tenant';
import AppContext from '../../contexts/AppContext';

export default function Administration() {
  const [isWhiteLabel, setIsWhiteLabel] = useState(false);
  const appStore = useContext(AppContext);
  const permCheck = useContext(RolesContext).userHasPermission;
  const userCanEdit = permCheck('all', 'Administration Features');
  const user = appStore.get("user");

  useEffect(()=>{
    tenantApi.features.index(user.tenantId, 'white_label').then((res)=>{
      if(res && res[0]){
        setIsWhiteLabel(res[0].is_enabled)
      }
    }).catch(e=>console.log(e));
  },[])

  return (
    <>
      {userCanEdit ? (
        <ResourceDetailTabs
          headers={['Settings', 'Features']}
          panels={[
            () => (
              <FormContextProvider>
                <SettingsTab />
                {isWhiteLabel && <UploadCompanyLogo />}
                <InspectionIntervals />
                <ProjectTypes />
                <RegionComponent />
              </FormContextProvider>
            ),
            () => (
              <FormContextProvider>
                <AllFeatures setIsWhiteLabel={setIsWhiteLabel} />
              </FormContextProvider>
            ),
          ]}
          showTab={true}
        />
      ) : (
        <ResourceDetailTabs
          headers={['Settings']}
          panels={[
            () => (
              <FormContextProvider>
                <SettingsTab />
                <InspectionIntervals />
                <ProjectTypes />
                <RegionComponent />
              </FormContextProvider>
            ),
          ]}
          showTab={true}
        />
      )}
    </>
  );
}
