import {
  CertificationIndexResponse,
  CreateApiParam,
  SignatureGroupType,
} from "@sw-sw/lib-certification";
import xhrService from "../xhrService";
import { baseUrl as baseInspectionUrl } from "./inspection";

export type BulkCertifyParam = { id: number; date: string; lineType: string }[];

export const certificationApi = {
  /**
   * @todo argument signatureLineId: string
   * @todo new api `${baseInspectionUrl}/${inspectionId}/signature/${signatureLineId}`
   */
  create: ({ inspectionId, group, ...data }: CreateApiParam, resend?: boolean) => {
    // data.date = new Date().toISOString();

    return xhrService.post<void>(
      `${baseInspectionUrl}/${inspectionId}/cert/${group}`,
      {data, resend},
    );
  },
  index: (inspectionId: number) => {
    return xhrService
      .get<CertificationIndexResponse>(`/api/inspections/${inspectionId}/cert`)
      .then(res => res.data);
  },

  bulkCertify: (data: BulkCertifyParam) => {    
    return xhrService.post(
      `${baseInspectionUrl}/cert/${SignatureGroupType.compliance}`,
      data,
    );
  },

  bulkInspectionCertify: (data: BulkCertifyParam) => {    
    return xhrService.post(
      `${baseInspectionUrl}/cert/${SignatureGroupType.inspection}`,
      data,
    );
  },

  bulkAcknowledgementCertify: (data: BulkCertifyParam) => {    
    return xhrService.post(
      `${baseInspectionUrl}/cert/${SignatureGroupType.acknowledgement}`,
      data,
    );
  },

  getAllSignedCompliancesWithCount: (inspectionId: number) => {
    return xhrService.get(`/api/inspections/${inspectionId}/cert/allSignedCompliancesWithCount`)
    .then(res => res.data)
  },

  removeCertification: (inspectionId: number, data: any) => {
    return xhrService.post(`/api/inspections/${inspectionId}/removeCertification`, data)
  }
};
