import React, { useState, useEffect, useRef, useContext } from "react";
import PropTypes from "prop-types";

import useMap from "../../../useMap";
import LegendCardContent from "../../Overlay/LegendCardContent";
import { Context } from "../../InteractionContext";


const handleBackgroundClick = (event, callback) => {
  if (
    !event.target.classList.contains("contextmenu") ||
    event.target.classList.contains("context-menu")
  ) {
    event.preventDefault();
    callback();
  }
};

export const extractMapEventFeature = (event, map) => {
  const lineFeatures = (map.getFeaturesAtPixel(event.pixel, {hitTolerance: 10}) || []).filter(
    _ => _.get("positionable_type"),
  );

  if(lineFeatures.length && lineFeatures[0].getGeometry().getType() === "LineString"){
    return lineFeatures.length ? lineFeatures[0] : null;
  }

  const features = (map.getFeaturesAtPixel(event.pixel) || []).filter(
    _ => _.get("positionable_type"),
  );

  return features.length ? features[0] : null;
};

/**
 * @note only one of these at a time, per map
 */
function ContextMenuInteractionReadOnly({ getProjectUploadOptions, featureOnly, featureFilter, readOnly, positionableSourceModel }) {
  const map = useMap();
  const [coordinates, setCoordinates] = useState(null);
  const [options, setOptions] = useState(null);
  const menuOpenTiming = useRef(Date.now());
  const [ showCard, setShowCard ] = useState(false)
  const [ featureObject, setFeatureObject ] = useState(null)
  const { isDrawing } = useContext(Context)

  const close = () => {
    setOptions(null);
    setCoordinates(null);
  };

  const handleSingleClick = event => {
    event.stopPropagation();
    event.preventDefault();
    const feature = extractMapEventFeature(event, map);

    if (
      feature &&
      typeof featureFilter === "function" &&
      !featureFilter(feature)
    ) {
      return;
    }

    const menuOptions = getProjectUploadOptions(event, feature).map(opt => ({
      ...opt,
      onClick: () => {
        opt.onClick({
          feature,
        });
        close();
      },
    }));

    if (menuOptions && menuOptions.length) {
      setCoordinates([
        event.originalEvent.clientX,
        event.originalEvent.clientY,
      ]);

      setOptions(menuOptions);

      const showMenu = featureOnly ? !!feature : true;

      setShowCard(showMenu);
      menuOpenTiming.current = Date.now();
      setFeatureObject(feature && feature.values_)
    }
    if(positionableSourceModel !== null){
      setShowCard(false);
    }
  };

  useEffect(() => {
    if (map) {
      map.on("click", handleSingleClick);
    }

    return () => {
      if (map) {
        map.un("click", handleSingleClick);
      }
    };
  }, [getProjectUploadOptions]);

  if (!isDrawing && showCard && coordinates && coordinates.length && options && options.length) {
    return (
      <div
      className="map-interaction-legend-card"
      onClick={(event) => {
        const currentTime = Date.now();
          const diff = Math.abs((menuOpenTiming.current - currentTime) / 1000);

        if (event.target.classList.contains("map-interaction-legend-card")) {
          if (diff > 0.5) {
            event.preventDefault()
            handleBackgroundClick(event, close)
          }
        }
      }}
      onContextMenu={e => {
        e.preventDefault();
        handleBackgroundClick(e, close);
      }}
      >
        <div className="map-editor__legend-cards-container">
          <LegendCardContent options={options} feature={featureObject} handleBackgroundClick={handleBackgroundClick} close={close} readOnly={readOnly}/>
        </div>
      </div>
    );
  }

  return null;
}

ContextMenuInteractionReadOnly.propTypes = {
  featureOnly: PropTypes.bool,
  featureFilter: PropTypes.func,
};

export default ContextMenuInteractionReadOnly;