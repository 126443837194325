import React, { useState, useEffect, useContext, useRef } from 'react';
import userApi from '../../utils/api/user';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import AppContext from '../../contexts/AppContext';
import { ActionButtons } from '../Shared/ActionButtons';
import { toast } from 'react-toastify';
import { useScreenshot } from 'use-react-screenshot';

const SelectFontSignature = () => {
  const appContext = useContext(AppContext);
  const [currentType, setCurrentType] = useState('');
  const [fontType, setFontType] = useState({ value: '', label: '' });
  const [selectedFontType, setSelectedFontType] = useState({ value: 'waterfall', label: 'Waterfall' });
  const animatedComponents = makeAnimated();
  const ref = useRef(null);
  const [, takeScreenshot] = useScreenshot();

  const user = appContext.get('user');

  const loadInitialData = () => {
    return userApi
      .getUserSignature()
      .then((userSignatureResponse) => {
        setCurrentType(userSignatureResponse.userSignature.type_of_signature);
        if (userSignatureResponse && userSignatureResponse.success === true) {
          if (userSignatureResponse.userSignature.font_type === 'waterfall') {
            setFontType({ value: 'waterfall', label: 'Waterfall' });
            setSelectedFontType({ value: 'waterfall', label: 'Waterfall' });
          } else if (
            userSignatureResponse.userSignature.font_type === 'hurricane'
          ) {
            setFontType({ value: 'hurricane', label: 'Hurricane' });
            setSelectedFontType({ value: 'hurricane', label: 'Hurricane' });
          } else if (
            userSignatureResponse.userSignature.font_type === 'stalemate'
          ) {
            setFontType({ value: 'stalemate', label: 'Stalemate' });
            setSelectedFontType({ value: 'stalemate', label: 'Stalemate' });
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleClick = async () => {
    const screenshot = await takeScreenshot(ref.current);

    const blob = await (await fetch(screenshot)).blob();

    const formData = new FormData();

    formData.append('file', blob);
    formData.append('type_of_signature', 'font_signature');
    formData.append('font_type', selectedFontType.value);

    return userApi
      .uploadUserSignature({ formData })
      .then((data) => {
        if (data && data.success === true) {
          toast.success('Signature Saved Successfully!');
          loadInitialData();
        } else {
          toast.error('Something went wrong, Font Type not saved!');
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleUseSignature = async () => {
    setCurrentType('font_signature');
    await userApi.useSignature('font_signature').then((data) => {
      if (data && data.success === true) {
        toast.success('Signature Used Successfully!');
      } else {
        toast.error('Something went wrong while using Signature!');
      }
    });
  }

  useEffect(() => {
    loadInitialData();
  }, []);

  return (
    <div className='select-font-type-container'>
      <div className='set-select-type-block'>
        <label>Select Font Type</label>
        <div className='responsive-select-type'>
          <Select
            classNamePrefix='responsive-select'
            components={animatedComponents}
            placeholder='Select Font Type'
            isSearchable={false}
            onChange={(selected) => setSelectedFontType(selected)}
            options={[
              { value: 'waterfall', label: 'Waterfall' },
              { value: 'hurricane', label: 'Hurricane' },
              { value: 'stalemate', label: 'Stalemate' },
            ]}
            menuPlacement='auto'
            value={selectedFontType}
          />
        </div>
      </div>

      {selectedFontType && (
        <div className='font-preview'>
          <div className='font-preview-signature' ref={ref} style={{ position: 'relative' }}>
            <span className={`font-preview-signature-text-${selectedFontType.value}`}>
              {user.name}
            </span>
            {fontType.value === selectedFontType.value && currentType === 'font_signature' &&
              <div style={{
                top: '0',
                right: '0',
                fontSize: 'small',
                position: 'absolute',
                textAlign: 'right',
                color: 'gray',
              }}>
                In Use
              </div>
            }
          </div>
        </div>
      )}
      <ActionButtons
        className='save-button-font-type-container'
        primary='Save'
        onClick={fontType.value === selectedFontType.value ? handleUseSignature : handleClick}
        primaryDisabled={!fontType || (fontType.value === selectedFontType.value && currentType === 'font_signature')}
      />
    </div>
  );
};

export default SelectFontSignature;
