import React, { useContext, useEffect, useState } from "react";
import { FormModal } from "../Shared/form";
import { FormContext, FormSchemaFields, ToggleSize } from "@sw-sw/lib-form";
import { UIControlType } from "@sw-sw/lib-form-control-types";
import inspectionIntervalsApi from "../../utils/api/inspectionIntervals";
import validator from "../../utils/FormValidator"

function getSchema(conditionalFormSchemaProperty:any, conditionalTwiceWeeklyProperties:any, formDataClone:any) {
  return {
    name: {
      label: 'Name',
      autoComplete: 'organization1',
      validation: {
        required: true,
      },
    },
    interval: {
      label: 'Interval',
      parse: validator.parseDecimal,
      placeholder: '',
      autoComplete: 'address-line1',
      'aria-label': 'Street address line 1 of 2',
      validation: {
        required: true,
      },
      style: {
        flexBasis: '33%',
      },
      disabled: (!formDataClone.exclude_business_days && formDataClone.twice_weekly) ? true : false
    },
    code: {
      label: "Code",
      autoComplete: 'code',
      validation: {
        required: false,
      },
      style: {
        flexBasis: '33%',
      },
    },
    weight: {
      label: "Weight",
      parse: validator.parseDecimal,
      validation: {
        required: false,
      },
      style: {
        flexBasis: '33%',
      },
    },
    exclude_business_days: {
      label: "Exclude Non-Business Days",
      controlType: UIControlType.toggleButton,
      checked: false,
      toggleSize: 'sm' as ToggleSize,
      disabled: formDataClone.twice_weekly ? true : false
    },
    exclude_weekends: {
      label: "Exclude Weekends",
      controlType: UIControlType.toggleButton,
      checked: false,
      toggleSize: 'sm' as ToggleSize,
      disabled: formDataClone.exclude_business_days
    },
    exclude_us_holidays: {
      label: "Exclude US Holidays",
      controlType: UIControlType.toggleButton,
      checked: false,
      toggleSize: 'sm' as ToggleSize,
      disabled: formDataClone.exclude_business_days
    },
    ...conditionalFormSchemaProperty,
    twice_weekly: {
      label: "Twice Weekly",
      controlType: UIControlType.toggleButton,
      checked: false,
      toggleSize: 'sm' as ToggleSize,
      disabled: formDataClone.exclude_business_days
    },
    ...conditionalTwiceWeeklyProperties
  };
}

const allInspectionOptions = [
  {value: "Sunday"},
  {value: "Monday"}, 
  {value: "Tuesday"},
  {value: "Wednesday"}, 
  {value: "Thursday"}, 
  {value: "Friday"}, 
  {value: "Saturday"}
]

const IntervalModal = (props: any) => {
  const { setshowIntervalModal, isEditAction, selectedIntervalDetails, inspectionIntervalsQuery } = props
  const formContext = useContext(FormContext);
  const [createResponse, setCreateResponse] = useState({})
  const [editResponse, setEditResponse] = useState({})
  const [deleteResponse, setDeleteResponse] = useState({})
  const [formDataClone, setFormDataClone] = useState(formContext.value)

  const firtInspectionOptions = allInspectionOptions.filter(ele => ele.value !== "Saturday")
  const indexOfFirstInspectionSelection = allInspectionOptions.findIndex(ele => ele.value === formDataClone.week1_first_inspection)
  const secondInspectionOptions = formDataClone.week1_first_inspection ? allInspectionOptions.slice(indexOfFirstInspectionSelection + 1) : allInspectionOptions.map(ele => ele)

  const conditionalTwiceWeeklyProperties = (!formDataClone.exclude_business_days && formDataClone.twice_weekly) ? 
  {
    week1_first_inspection: {
      label: 'First Inspection',
      controlType: UIControlType.select,
      disabled: (!formDataClone.exclude_business_days && formDataClone.twice_weekly) ? false : true,
      options: firtInspectionOptions,
      labelKey: 'value',
      valueKey: 'value',
      style: {
        flexBasis: '50%',
      },
      validation: {
        required: formDataClone.twice_weekly,
      },
    },
    week1_second_inspection: {
      label: 'Second Inspection',
      controlType: UIControlType.select,
      disabled: (!formDataClone.exclude_business_days && formDataClone.twice_weekly) ? false : true,
      options: secondInspectionOptions,
      labelKey: 'value',
      valueKey: 'value',
      style: {
        flexBasis: '50%',
      },
      validation: {
        required: formDataClone.twice_weekly,
      },
    },
  } : {}

  const handleCreate = async(formData:any) => {
    try{
      const createResponsedata = await inspectionIntervalsApi.post(`/inspection-intervals`, {data: formData})
      
      setEditResponse(createResponsedata)
      setshowIntervalModal(false)
    }
    catch(error){
      console.log(error)
    }
  }

  const handleEdit = async(formData:any) => {
    try{
      const editResponsedata = await inspectionIntervalsApi.update(`/inspection-intervals/${selectedIntervalDetails.id}`, {data: formData})

      await inspectionIntervalsApi.updateNextInspectionDateForIntervalId(selectedIntervalDetails.id)

      setCreateResponse(editResponsedata)
      setshowIntervalModal(false)
    }
    catch(error){
      console.log(error)
    }
  }

  const handleDelete = async() => {
    try{
      const deletedResponsedata = await inspectionIntervalsApi.delete(`/inspection-intervals/${selectedIntervalDetails.id}`)

      setDeleteResponse(deletedResponsedata)
      setshowIntervalModal(false)
    }
    catch(error){
      console.log(error)
    }
  }

  const conditionalFormSchemaProperty = (!formDataClone.exclude_business_days && (formDataClone.exclude_weekends || formDataClone.exclude_us_holidays)) ? 
  {
    before_after: {
      label: 'Next inspection to be scheduled',
      controlType: UIControlType.select,
      disabled: (formDataClone.exclude_weekends || formDataClone.exclude_us_holidays) ? false : true,
      options: [{value: "BEFORE"}, {value: "AFTER"}],
      labelKey: 'value',
      valueKey: 'value'
    },
  } : {}

  useEffect(() => {
    inspectionIntervalsQuery.refetch()
  },[createResponse, editResponse, deleteResponse])

  useEffect(() => {
    setFormDataClone(formContext.value)
  },[formContext.value])

  return (
    <FormModal
      modalProps={{
        title: `${isEditAction ? 'Edit Interval' : 'Create Interval'}`,
        submitBtnText: "Submit",
      }}
      onSubmit={(formData) => isEditAction ? handleEdit(formData) : handleCreate(formData)}
      onCancel={() => setshowIntervalModal(false)}
      showDelete={isEditAction ? true : false}
      onDelete={() => handleDelete()}
    >
      <FormSchemaFields
        schema={getSchema(conditionalFormSchemaProperty, conditionalTwiceWeeklyProperties, formDataClone)}
        onChange={formContext.set}
        formData={formContext.value}
        initialFormData={isEditAction ? selectedIntervalDetails : {}}
      />
    </FormModal>
  );
};

export default IntervalModal;
