import React, { useContext, useState } from 'react';
import { string, bool, func, number } from 'prop-types';

import InspectionContext from '../../../contexts/InspectionContext';
import { RolesContext } from '../../../contexts/RolesContext';
import AppDivisionContext from '../../../contexts/AppDivisionContext';
import DashboardContext from '../../../contexts/DashboardContext';
import ConfirmationModal from '../../Shared/ConfirmationModal/ConfirmationModal';
import { Redirect } from 'react-router-dom';
import inspectionApi from '../../../utils/api/inspection';
import ActionButtons from '../../Shared/ActionButtons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from '../../Shared/ResourceIndex/Loading';
import { ProjectContext } from '../../../contexts/ProjectContext';
import TenantFeatureContext from '../../../contexts/TenantContext';
import WeatherWidget from '../../Dashboard/WeatherWidget';
import SuccessNotification from '../../Shared/SuccessNotification/SuccessNotification';
import ProjectPermissionContext from '../../../contexts/ProjectPermissionContext';

function InspectionHeader({
  title,
  projectName,
  clientName,
  enablePrint,
  handlePrint,
  handleSiteMapPrint,
  inspectionNumber,
  inspectionId,
  customTab,
  handleAddToReport,
  showModal,
}) {
  const projectContext = useContext(ProjectContext);
  const inspectionStore = useContext(InspectionContext);
  const roleStore = useContext(RolesContext);
  const featureContext = useContext(TenantFeatureContext);
  const { fetchStatsFuncForID, getTeamManagementData } =
    useContext(DashboardContext);
  const { getPath, getPublicPath } = useContext(AppDivisionContext);
  const { isInspectionHeaderLoader } = useContext(ProjectContext);
  const userHasPermission = useContext(RolesContext).userHasPermission;
  const projectPermissionContext = useContext(ProjectPermissionContext);

  const [showDelete, setShowDelete] = useState(false);
  const [toProject, setToProject] = useState(false);
  const [showForecast, setShowForecast] = useState(false);

  const isPublic = window.location.pathname.startsWith('/public');

  // init actions

  const secondaryActions = [
    {
      label: 'Back to Project',
      icon: 'folder',
      onClick: () => setToProject(true),
    },
  ];

  if (roleStore.userHasPermission('delete', 'Inspection')) {
    secondaryActions.push({
      label: 'Delete Inspection',
      icon: 'trash',
      onClick: () => setShowDelete(true),
    });
  }

  /**
   * Print should be last
   *
   * @todo enable this button via a "Print Inspection" permission
   * if (roleStore.userHasPermission('all', 'Print Inspection')) { }
   * */
  if (enablePrint && roleStore.userHasPermission('all', 'Download')) {
    secondaryActions.push({
      label: 'Print Inspection',
      icon: 'print',
      onClick: handlePrint,
    });
  }

  if (featureContext.tenantHasFeature('Site Map - Editor Updates')) {
    secondaryActions.push({
      icon: 'print',
      label: 'Print Site Map',
      onClick: handleSiteMapPrint,
    });
  }

  if (window.innerWidth < 568) {
    secondaryActions.push({
      icon: 'cloud',
      label: 'Show Forecast',
      onClick: () => {
        setShowForecast(!showForecast);
        projectContext.fetchWeatherData();
      },
    });
  }

  // render
  if (toProject) {
    if (isPublic) {
      return customTab ? (
        <Redirect
          to={getPublicPath(
            `/projects/${inspectionStore.getProjectId()}/project-docs`,
          )}
          push
        />
      ) : (
        <Redirect
          to={getPublicPath(
            `/projects/${inspectionStore.getProjectId()}/inspections`,
          )}
          push
        />
      );
    }

    return customTab ? (
      <Redirect
        to={getPath(`/projects/${inspectionStore.getProjectId()}/project-docs`)}
        push
      />
    ) : (
      <Redirect
        to={getPath(`/projects/${inspectionStore.getProjectId()}/inspections`)}
        push
      />
    );
  }

  if (isInspectionHeaderLoader) {
    return <Loading what='details' />;
  }

  return (
    <>
    {showForecast && (
      <div className='forecast-min-width'>
        <div style={{ display: 'grid' }}>
          <WeatherWidget isProject={true} />
          {!projectPermissionContext.readOnly &&
            userHasPermission('create', 'Inspection Notes') && (
              <ActionButtons
                primary='Add to Report'
                className='small-button-add-to-report'
                onClick={handleAddToReport}
              />
            )}
        </div>
      </div>)}

      <header className='inspection-header'>
        <div className='inspection-header-info text-truncate'>
          <h2>{title}</h2>
          <h5>
            <FontAwesomeIcon icon='hashtag' className='header-icon' />
            {inspectionNumber} | ID {inspectionId}
          </h5>
          <h5>
            <FontAwesomeIcon icon='folder' className='header-icon' />
            {projectName}
          </h5>
          <h5>
            <FontAwesomeIcon icon='user' className='header-icon' />
            {clientName}
          </h5>
        </div>

        <div className='project-header-buttons'>
          <ActionButtons secondary={secondaryActions} />
          <div className='forecast-max-width'>
            {showForecast ? (
              <div style={{ display: 'grid' }}>
                <WeatherWidget isProject={true} />
                {!projectPermissionContext.readOnly &&
                  userHasPermission('create', 'Inspection Notes') && (
                    <ActionButtons
                      primary='Add to Report'
                      className='small-button-add-to-report'
                      onClick={handleAddToReport}
                    />
                  )}
              </div>
            ) : (
              <ActionButtons
                primary='Show Forecast'
                onClick={() => {
                  setShowForecast(!showForecast);
                  projectContext.fetchWeatherData();
                }}
              />
            )}
          </div>
        </div>

        <SuccessNotification show={showModal === 'success'} />
        {showDelete && (
          <ConfirmationModal
            show={showDelete}
            title='Are you sure you want to delete this inspection?'
            subTitle=' The inspection will be hidden from all views. This action cannot be reverted.'
            buttonText='Delete'
            handleClose={() => setShowDelete(false)}
            handleConfirm={() =>
              inspectionApi.destroy(inspectionStore.inspectionId).then(() => {
                setToProject(true);
                fetchStatsFuncForID.refetch();
                getTeamManagementData.refetch();
              })
            }
          />
        )}
      </header>
    </>
  );
}

InspectionHeader.propTypes = {
  title: string.isRequired,
  enablePrint: bool,
  handlePrint: func,
  inspectionNumber: number,
  inspectionId: number,
  handleAddToReport: func.isRequired,
  showModal: string.isRequired,
};

InspectionHeader.defaultProps = {
  enablePrint: false,
  handlePrint: () => {},
  handleSiteMapPrint: () => {},
  inspNumber: 0,
  inspId: 0,
};

export default InspectionHeader;
