import { Button } from '@sw-sw/lib-ui';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormModal } from '../../../Shared/form';
import { FormContext, FormSchemaFields } from '@sw-sw/lib-form';
import { UIControlType } from '@sw-sw/lib-form-control-types';
import { Placement } from 'popper.js';
import projectApi from '../../../../utils/api/project';
import { ProjectContext } from '../../../../contexts/ProjectContext';
import moment from 'moment';
import Pagination from 'react-js-pagination';
import _ from 'lodash';
import Loading from '../../../Shared/ResourceIndex/Loading';
import ReactTooltip from 'react-tooltip';
import findingTypesApi from '../../../../utils/api/findingTypes';
import ExcelJs from 'exceljs';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

const findingsStatusOptions = [
  { name: 'All Findings', value: 'all' },
  { name: 'Open Findings', value: 'open' },
  { name: 'Completed Findings', value: 'closed' },
];

const FindingsReport: React.FC<any> = () => {
  const widgetRef = useRef<any>(0);
  const [generateModal, setGenerateModal] = useState(true);
  const [isDownloadModal, setIsDownloadModal] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(450);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [findingsReportData, setFindingsReportData] = useState<any[]>([]);
  const [findingTypes, setFindingTypes] = useState<any[]>([])
  const formContext = useContext(FormContext);
  const projectContext = useContext(ProjectContext);
  const projectId =
    projectContext && projectContext.project && projectContext.project.id;

  const downloadOptions = [
    { name: 'PDF' },
    { name: 'Excel' },
  ]

  useEffect(() => {
    getFindingsReport();
  }, [projectId, page, rowsPerPage, search]);

  useEffect(() => {
    getFindingsType();
  }, [projectContext.project?.inspection_template_id])

  const dateDisplayFunc = (ele: any) => {
    return moment(ele).utc().format('MM/DD/YYYY');
  };

  function getSchema() {
    return {
      finding_status: {
        label: 'Select Findings Status*',
        controlType: UIControlType.select,
        placeholder: 'Select Findings Status',
        options: findingsStatusOptions,
        labelKey: 'name',
        valueKey: 'value',
        validation: {
          required: true,
        },
      },
      finding_type: {
        label: 'Select Findings Type*',
        controlType: UIControlType.select,
        placeholder: 'Select Findings Type',
        options: findingTypes,
        labelKey: 'label',
        valueKey: 'value',
        validation: {
          required: true,
        },
      },
      start_date: {
        controlType: UIControlType.date,
        label: 'Start Date*',
        style: { flex: '0 1 50%' },
        popperPlacement: 'top' as Placement,
        validation: {
          required: true,
        },
      },
      end_date: {
        controlType: UIControlType.date,
        label: 'End Date*',
        style: { flex: '0 1 50%' },
        popperPlacement: 'top' as Placement,
        validation: {
          maxDate: new Date(),
          required: true,
        },
      },
    };
  }

  function getSchemaDownload() {
    return {
      format: {
        label: 'Format',
        controlType: UIControlType.select,
        placeholder: "Select",
        "aria-label": "State",
        autoComplete: "address-level1",
        options: downloadOptions,
        labelKey: 'name',
        valueKey: 'name',
        style: { flex: '1 1 50%' },
        validation: {
          required: true,
        },
      },
    }
  }

  function getFindingsReport() {
    const formData = formContext.value;
    const data = {
      findingStatus: formData.finding_status,
      findingType: formData.finding_type,
      startDate: moment(formData.start_date).format('MM/DD/YYYY'),
      endDate: moment(formData.end_date).format('MM/DD/YYYY'),
      p: page,
      rows: rowsPerPage,
      search: search,
    };
    if (data.findingType && data.findingStatus)
      projectApi.getFindingsReport(projectId, data).then((res) => {
        setFindingsReportData(res.data);
        setCount(res.count);
      });
  }

  async function getFindingsType() {
    if (projectContext.project?.inspection_template_id) {
      const data = await findingTypesApi.index(projectContext.project?.inspection_template_id)
      if (data.length === 0) {
        setFindingTypes([{ value: 'all', label: 'All Findings' }, { value: 'A', label: 'Achievement' }, { value: 'MI', label: 'Maintenance Item' }, { value: 'CA', label: 'Corrective Action' }])
      } else {
        const findingName = data.filter((ele: any) => ele.details.is_enabled)
          .map((ele: any) => ({ value: ele.name, label: handleName(ele.name) }))

        setFindingTypes([{ value: 'all', label: 'All Findings' }, ...findingName])
      }
    }
  }

  function handleGenerate() {
    getFindingsReport();
    setGenerateModal(false);

    return Promise.resolve();
  }

  function handleExcelDownload() {
    const workbook = new ExcelJs.Workbook();
    const worksheet = workbook.addWorksheet('Findings Report');

    worksheet.columns = [
      { header: 'Number', key: 'number', width: 15 },
      { header: 'Type', key: 'type', width: 15 },
      { header: 'Item', key: 'item', width: 15 },
      { header: 'Observation', key: 'observation', width: 15 },
      { header: 'Location', key: 'location', width: 15 },
      { header: 'Date Initiated', key: 'dateInitiated', width: 15 },
      { header: 'Date Closed', key: 'dateClosed', width: 15 },
      { header: 'Days Open', key: 'daysOpen', width: 15 },
      { header: 'Initiated By', key: 'initiatedBy', width: 15 },
      { header: 'Closed By', key: 'closedBy', width: 15 },
    ];

    const formData = formContext.value;
    const data = {
      findingStatus: formData.finding_status,
      findingType: formData.finding_type,
      startDate: moment(formData.start_date).format('MM/DD/YYYY'),
      endDate: moment(formData.end_date).format('MM/DD/YYYY'),
      p: 1,
      rows: count,
      search: search,
    };

    projectApi.getFindingsReport(projectId, data).then((res) => {
      res.data.forEach((ele: any) => {
        if (ele.type === 'CA' || ele.type === 'MI') {
          ele.type =
            ele.type === 'CA'
              ? 'Corrective Actions'
              : 'Maintenance Items';
        }

        worksheet.addRow({
          number: ele.number,
          type: ele.type,
          item: ele.legend_item_name ? ele.legend_item_name : 'NA',
          observation: ele.finding_note_content ? ele.finding_note_content : 'NA',
          location: ele.location,
          dateInitiated: dateDisplayFunc(ele.date_initiated),
          dateClosed: ele.date_completed ? dateDisplayFunc(ele.date_completed) : 'NA',
          daysOpen: ele.days_open || ele.days_open == 0 ? ele.days_open : 'NA',
          initiatedBy: ele.created_user_first_name ? ele.created_user_first_name + ' ' + ele.created_user_last_name : 'NA',
          closedBy: ele.user_first_name ? ele.user_first_name + ' ' + ele.user_last_name : 'NA',
        });
      });

      workbook.xlsx.writeBuffer().then((data: any) => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${projectContext.project?.name}_Findings_Report_${dateDisplayFunc(new Date())}.xlsx`;
        a.click();
      });
    });
  }

  function handlePdfDownload() {
    let tableBodyData: any[] = []

    const formData = formContext.value;
    const data = {
      findingStatus: formData.finding_status,
      findingType: formData.finding_type,
      startDate: moment(formData.start_date).format('MM/DD/YYYY'),
      endDate: moment(formData.end_date).format('MM/DD/YYYY'),
      p: 1,
      rows: count,
      search: search,
    };

    projectApi.getFindingsReport(projectId, data).then((res) => {
      tableBodyData = res.data.map((ele: any) => {
        const number = ele.number;
        const type = ele.type;
        const legendName = ele.legend_item_name ? ele.legend_item_name : 'NA';
        const observation = ele.finding_note_content ? ele.finding_note_content : 'NA';
        const location = ele.location;
        const dateInitiated = dateDisplayFunc(ele.date_initiated);
        const dateCompleted = ele.date_completed ? dateDisplayFunc(ele.date_completed) : 'NA'
        const daysOpen = ele.days_open || ele.days_open == 0 ? ele.days_open : 'NA';
        const createdUser = ele.created_user_first_name ? ele.created_user_first_name + ' ' + ele.created_user_last_name : 'NA'
        const completedUser = ele.user_first_name ? ele.user_first_name + ' ' + ele.user_last_name : 'NA'
        return [number, type, legendName, observation, location, dateInitiated, dateCompleted, daysOpen, createdUser, completedUser]
      })

      const doc = new jsPDF({
        orientation: 'landscape',
      });

      autoTable(doc, {
        head: [['Number', 'Type', 'Item', 'Observation', 'Location', 'Date Initiated', 'Date Closed', 'Days Open', 'Initiated By', 'Closed By']],
        body: tableBodyData
      })

      doc.save(`${projectContext.project?.name}_Findings_Report_${dateDisplayFunc(new Date())}`)
    });
  }

  const onSearchChangeHandler = _.debounce((event: any) => {
    setSearch(event.target.value);
  }, 500);

  const handleName = (name: any) => {

    if (name === "A") {
      return "Achievement"
    }
    else if (name === "MI") {
      return "Maintenance Item"
    }
    else if (name === "CA") {
      return "Corrective Action"
    }
    else {
      return name
    }
  }

  return (
    <div className='findings-report'>
      <header>
        <h2 className='findings-report__header-name'>Findings Report</h2>
        <div className='findings-report__header-tools'>
          <input onChange={onSearchChangeHandler} placeholder='Search' />
          <Button
            className='findings-report__show-results-btn'
            show-results-btn
            onClick={() => setGenerateModal(true)}
          >
            Generate
          </Button>
          <Button
            className='findings-report__download-btn'
            onClick={() => setIsDownloadModal(true)}
          >
            Download
          </Button>
        </div>
      </header>
      <main>
        <>
          <div className='findings-report-table' ref={widgetRef}>
            <div className='findings-report-table__body'>
              <div className='findings-report-table__content'>
                <section className='findings-report-table__list-view'>
                  <div className='findings-report-table__table-head'>
                    <h3>Finding Number</h3>
                    <h3>Finding Type</h3>
                    <h3>Item</h3>
                    <h3>Observation</h3>
                    <h3>Location</h3>
                    <h3>Date Initiated</h3>
                    <h3>Date Closed</h3>
                    <h3>Days Open</h3>
                    <h3>Initiated By</h3>
                    <h3>Closed By</h3>
                  </div>

                  {findingsReportData ?
                    findingsReportData.length > 0 ? (
                      findingsReportData.map((ele: any, index: any) => {
                        if (ele.type === 'CA' || ele.type === 'MI') {
                          ele.type =
                            ele.type === 'CA'
                              ? 'Corrective Actions'
                              : 'Maintenance Items';
                        }

                        return (
                          <div
                            key={index}
                            className='findings-report-table__table-body'
                          >
                            <li style={{ textAlign: 'center' }}>{ele.number}</li>
                            <li data-tip={`${ele.type}`}>
                              <ReactTooltip
                                place='top'
                                type='dark'
                                effect='float'
                              />{ele.type}
                            </li>
                            <li
                              data-tip={`${ele.legend_item_name ? ele.legend_item_name : 'NA'}`}
                              style={{ textAlign: ele.legend_item_name ? 'left' : 'center' }}
                            >
                              <ReactTooltip
                                place='top'
                                type='dark'
                                effect='float'
                              />
                              {ele.legend_item_name ? ele.legend_item_name : 'NA'}
                            </li>
                            <li data-tip={`${ele.finding_note_content ? ele.finding_note_content : 'NA'}`}
                              style={{ textAlign: ele.finding_note_content ? 'left' : 'center' }}
                            >
                              <ReactTooltip
                                place='top'
                                type='dark'
                                effect='float'
                              />
                              {ele.finding_note_content ? ele.finding_note_content : 'NA'}
                            </li>
                            <li data-tip={`${ele.location}`}>
                              <ReactTooltip
                                place='top'
                                type='dark'
                                effect='float'
                              />{ele.location}
                            </li>
                            <li style={{ textAlign: 'center' }}>{dateDisplayFunc(ele.date_initiated)}</li>
                            <li style={{ textAlign: 'center' }}>{ele.date_completed ? dateDisplayFunc(ele.date_completed) : 'NA'}</li>
                            <li style={{ textAlign: 'center' }}>{ele.days_open || ele.days_open == 0 ? ele.days_open : 'NA'}</li>
                            <li>{ele.created_user_first_name ? ele.created_user_first_name + ' ' + ele.created_user_last_name : 'NA'}</li>
                            <li>{ele.user_first_name ? ele.user_first_name + ' ' + ele.user_last_name : 'NA'}</li>
                          </div>
                        );
                      })
                    ) : (
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        No Findings
                      </div>
                    ) : <Loading what={'Loading'} />
                  }
                </section>
              </div>
            </div>
          </div>
        </>
        <div className='findings-report__pagination-wrapper'>
          <div>
            <span>Rows Per Page : </span>
            <select
              className='findings-report__pagination-select'
              onChange={(event) => {
                setRowsPerPage(Number(event.target.value));
              }}
            >
              <option value='10'>10</option>
              <option value='20'>20</option>
              <option value='30'>30</option>
              <option value='40'>40</option>
              <option value='50'>50</option>
            </select>
          </div>
          <Pagination
            activePage={page}
            itemsCountPerPage={rowsPerPage}
            totalItemsCount={count}
            pageRangeDisplayed={3}
            onChange={(currentPage) => {
              setPage(currentPage);
            }}
            itemClass='findings-report__pagination-main'
          />
        </div>
      </main>

      {generateModal && (
        <FormModal
          modalProps={{
            title: 'Select Report Paramters',
            submitBtnText: 'Generate',
            cancelBtnText: 'Cancel',
          }}
          onSubmit={handleGenerate}
          onCancel={() => setGenerateModal(false)}
        >
          <FormSchemaFields
            schema={getSchema()}
            onChange={formContext.set}
            formData={formContext.value}
          />
        </FormModal>
      )}

      {isDownloadModal &&
        <FormModal
          modalProps={{
            title: 'Print Findings Report',
            submitBtnText: "Download",
          }}
          onSubmit={(formData) => {
            if (formData.format === 'Excel') {
              handleExcelDownload()
              setIsDownloadModal(false)
            }

            if (formData.format === 'PDF') {
              handlePdfDownload()
              setIsDownloadModal(false)
            }

            return Promise.resolve()
          }}
          onCancel={() => setIsDownloadModal(false)}
        >
          <FormSchemaFields
            schema={getSchemaDownload()}
            onChange={formContext.set}
            formData={formContext.value}
          />
        </FormModal>
      }
    </div>
  );
};

export default FindingsReport;
