/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useRouteMatch } from "react-router-dom";
import AppContext from "../../contexts/AppContext";
import AppDivisionContext from "../../contexts/AppDivisionContext";
import ProjectContext from "../../contexts/ProjectContext";
import ProjectPermissionContext, {
  ProjectPermissionContextProvider,
} from "../../contexts/ProjectPermissionContext";
import RolesContext from "../../contexts/RolesContext";
import { setProjectInspectionsQueryData } from "../../hooks/inspection";
import { useProject } from "../../hooks/project";
import uploadApi from "../../utils/api/upload";
import { projectTabs } from "../../utils/tabs/projectTabs";
import NavTabs from "../Shared/NavTabs";
import Error from "../Shared/ResourceIndex/Error";
import Loading from "../Shared/ResourceIndex/Loading";
import ProjectHeader from "./Header/ProjectHeader";
import { clientApi } from "../../utils/api/client";
import TenantFeatureContext from "../../contexts/TenantContext";
// import { datadogRum } from '@datadog/browser-rum';


const ProjectDetailUI = ({ ...props }) => {
  const match = useRouteMatch();
  const queryClient = useQueryClient();
  const projectQuery = useProject(match.params.id);
  const [clientTab, setClientTab] = useState()
  const [customTab, setCustomTab] = useState()
  const [clientName, setClientName] = useState('')
  const [finalTabslist, setFinalTabslist] = useState([]);

  const appContext = useContext(AppContext);
  const featureContext = useContext(TenantFeatureContext);
  const projectContext = useContext(ProjectContext);
  const { getPath } = useContext(AppDivisionContext);
  const projectPermissionContext = useContext(ProjectPermissionContext);
  const loaded = useMemo(() => {
    return projectContext.project; // && projectQuery.isFetched;
  }, [projectContext.project, projectQuery.isLoading, projectQuery.isFetching]);
  const error = useMemo(() => {
    if (projectQuery.isError) {
      return 'Error loading project' + projectQuery.error;
    } else {
      return null;
    }
  }, [projectQuery.isError, projectQuery.error]);

  useEffect(() => {
    const clientId = loaded && projectContext.project.client_id

    if (clientId) {
      clientApi.show(clientId).then((res) => {
        setCustomTab(res.custom_tab)
        setClientName(res.name)
      
        // datadogRum.addAction('projectDetail', {
        //   clientName:res.name 
        // });
      })
      clientApi.getClientsProjectTab.index(clientId).then((res) => {
        setClientTab(res)
      })
    }
  }, [loaded, projectContext.project])


  useEffect(() => {
    const finalTablist = getCustomTabs();

    setFinalTabslist(finalTablist);
  }, [customTab, clientTab]);


  const showUpdateForm = false;

  const getCustomTabs = () => {

    let roleBaseProjectTabs = projectTabs.filter(e => {
      if (e.label === "Project Settings") {
        return props.permCheck('update', 'Project')
      }
      
      return props.permCheck('all', `${e.label} Tab`)
    });

    if (customTab) {
      if (!clientTab.length) {
        return roleBaseProjectTabs.map((ele) => {
          if(ele.label === "Project Settings") {
            return {
              ...ele,
              showTab: () => props.permCheck('update', 'Project')
            }
          }

          if (ele.label === "Project Documents") {
            return {
              ...ele,
              showTab: () => true
            }
          }

          return {
            ...ele,
            showTab: () => false
          }

        })
      }
      if (clientTab.length) {
        const clientTabName = clientTab.map(ele => ele.tabs.name)

        return roleBaseProjectTabs.map((ele) => {

          if(ele.label === "Project Settings") {
            return {
              ...ele,
              showTab: () => props.permCheck('update', 'Project')
            }
          }

          if (ele.label === "Project Documents") {
            return {
              ...ele,
              showTab: () => true
            }
          } else if (!clientTabName.includes(ele.label)) {
            return {
              ...ele,
              showTab: () => false
            }
          }

          return ele
        })
      }
    }

    let restricted_tabs = []

    if (projectContext.project?.external_project) restricted_tabs.push("Site Photos", "Findings");

    if (!featureContext.tenantHasFeature("Site Map - Editor Updates")) restricted_tabs.push("Reports");

    if (!featureContext.tenantHasFeature("Enable Photo Uploads")) restricted_tabs.push("Site Photos");

    roleBaseProjectTabs.forEach(e => {
      if (!restricted_tabs.includes(e.label)) {
        e.showTab = () => true;
      } else {
        e.showTab = () => false;
      }
    });

    return roleBaseProjectTabs
  }

  const onDocumentDelete = guid => {
    const { projectDocs, updateProjectDocs } = projectContext;

    updateProjectDocs({
      documentTypes: projectDocs.documentTypes.map((docType) => ({
        ...docType,
        documents: docType.documents.filter((doc) => doc.GUID !== guid),
      })),
    });
  };

  const onMove = async ({ document, dragIndex, hoverIndex }, group) => {
    const { projectDocs, updateProjectDocs } = projectContext;
    const documentTypes = projectDocs.documentTypes;
    const documentTypeIndex = documentTypes.findIndex(
      (documentType) => documentType.name === group,
    );

    if (documentTypeIndex < 0) {
      return;
    }

    const docType = documentTypes[documentTypeIndex];
    const draggingDocument = docType.documents[dragIndex];

    docType.documents.splice(dragIndex, 1);

    docType.documents.splice(hoverIndex, 0, draggingDocument);

    updateProjectDocs({ documentTypes });
  };

  const onDrop = ({ document, index }, group) => {
    const model = document.ProjectUploadModel || document.project_uploads;

    return uploadApi.project.sort({
      id: model.id,
      order: index,
      projectId: model.project_id,
      projectDocTypeId: model.project_doc_type_id,
    });
  };

  const deleteProject = () => {
    props.history.push(getPath('/projects'));
  };

  const shouldRenderRegulations = () => props.permCheck('read', 'Regulations');

  useEffect(() => {
    if (
      props.location.state &&
      props.location.state.project &&
      props.location.state.project.id &&
      props.location.state.project.inspections
    ) {
      projectContext.setProject(props.location.state.project);

      setProjectInspectionsQueryData({
        queryClient,
        projectId: props.location.state.project.id,
        data: props.location.state.project.inspections,
      });
    }

    return () => projectContext.clear();
  }, []);

  useEffect(() => {
    if (!projectQuery.isFetching) {
      projectContext.setProject(projectQuery.data);
    }
  }, [projectQuery.isFetching]);

  useEffect(() => {
    if (loaded && projectContext.project && projectContext.project.archivedAt) {
      projectPermissionContext.setReadOnly(true);
    } else {
      projectPermissionContext.setReadOnly(false);
    }
  }, [loaded]);

  useEffect(() => {
    const finalTablist = getCustomTabs();
    setFinalTabslist(finalTablist);
  }, [projectContext.project]);

  return (
    <section className='project-detail'>
      {error ? <Error error={error} /> : null}
      {loaded && clientTab ? (
        <React.Fragment>
          <ProjectHeader onDelete={deleteProject} clientName={clientName} />
          <NavTabs
            tabs={finalTabslist}
            getPropFuncArgs={() => {
              // REFACTOR
              return [
                {
                  contacts: [],
                  showUpdateForm,
                  loaded,
                  error,
                },
                {
                  onDocumentUpload: () =>
                    appContext.loadData(
                      () => projectContext.loadDocs(projectQuery.data.id),
                      'Documents',
                    ),
                  onDocumentDelete: onDocumentDelete,
                  shouldRenderRegulations: shouldRenderRegulations,
                  context: projectContext,
                  onMove: onMove,
                  onDrop: onDrop,
                },
              ];
            }}
            url={match.url}
          />
        </React.Fragment>
      ) : (
        <Loading />
      )}
    </section>
  );
};

export const ProjectDetail = (props) => {
  const permCheck = useContext(RolesContext).userHasPermission;

  return (
    <ProjectPermissionContextProvider>
        <ProjectDetailUI {...props} permCheck={permCheck} />
    </ProjectPermissionContextProvider>
  );
};

export default ProjectDetail;
