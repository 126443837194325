
import React from "react";
import { Modal } from "@sw-sw/lib-ui";

export interface IClearComplianceComfirmationModalProps {
  onClose: () => void;
  Submit: () => void;
  inspections: string[];
}

const ClearComplianceComfirmationModal: React.FC<IClearComplianceComfirmationModalProps> =
  ({ onClose, Submit,inspections }) => {

    const onSubmit = async () => {
        Submit();
    };
  

    return (
      <Modal
        title="Submit Finding Confirmation"
        subTitle= {`Are you sure you want to submit the finding? The following inspections ${inspections.map(ele=>ele)} will have their compliance signatures removed. This action cannot be undone.`}
        show={true}
        submitBtnText="Confirm"
        handleSubmit={onSubmit}
        handleClose={onClose}
        cancelBtn={true}
      >
       
      </Modal>
    );
  };

export default ClearComplianceComfirmationModal;
