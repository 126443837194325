import xhrService from '../xhrService';

/**
 * Finish types
 *
 */
export type ComplianceReport = {
  paginationState: Object;
  result: [
    {
      projectName: string;
      inspectionCount: number;
      findingsCount: number;
      avgFindings: number;
      maxDay: number;
      openFindings: number;
      avgFindingDays: number;
      notCompleteInExpectedDays: number;
      complianceCount: number;
      ackCount: number;
    },
  ];
};

export type ProjectActivityLogReport = {
  date: Date;
  activity: string;
  clientName: string;
  clientCode: string;
  subClientName: string;
  projectName: string;
  frequency: string;
  type: string;
  additionalNotes: string;
}[];

export type FindingsDetails = {
  findings: string[];
};

export type IndexApi = {
  divisionId: number;
  pageNumber?: number;
  searchQuery?: string;
  rowsPerPage?: number;
  client?: number[];
  maxDate?: Date;
  minDate?: Date;
  findings?: string;
  showArchived?: boolean;
};

export type ProjectActivityLogApi = {
  clientIds: number[];
  maxDate?: any;
  minDate?: any;
  projectIds: number[];
  divisionIds: number[];
  divisionId: number;
};

export type FindingApi<D = string> = (divisionId: number) => Promise<D[]>;

/**
 * Go through types
 */
type ReportApi = {
  index: (param: IndexApi) => Promise<ComplianceReport>;
  getFindings: (divisionId: number) => Promise<FindingsDetails>;
  getProjectActivityLogs: (
    param: ProjectActivityLogApi,
  ) => Promise<ProjectActivityLogReport>;
};

const paramsSerializer = (params: any) => {
  return Object.keys(params)
    .map((key) => {
      if (Array.isArray(params[key])) {
        return params[key]
          .map((val: any) => `${key}[]=${encodeURIComponent(val)}`)
          .join('&');
      }
      
      return `${key}=${encodeURIComponent(params[key])}`;
    })
    .join('&');
};

export const reportApi: ReportApi = {
  index: (param) => {
    const params: {
      s?: string;
      p?: number;
      r?: number;
      client?: number[];
      maxDate?: Date;
      minDate?: Date;
      findings?: string;
      showArchived?: boolean;
    } = {};

    if (param.searchQuery) {
      params.s = param.searchQuery;
    }

    if (param.pageNumber) {
      params.p = param.pageNumber;
    }

    if (param.rowsPerPage) {
      params.r = param.rowsPerPage;
    }

    if (param.client) {
      params.client = param.client;
    }
    if (param.maxDate) {
      params.maxDate = param.maxDate;
    }
    if (param.minDate) {
      params.minDate = param.minDate;
    }
    if (param.findings) {
      params.findings = param.findings;
    }
    if (param.showArchived) {
      params.showArchived = param.showArchived;
    }

    return xhrService
      .get(`/api/divisions/${param.divisionId}/compliance`, {
        params,
        paramsSerializer,
      })
      .then((response) => response.data);
  },

  getFindings: (divisionId) => {
    return xhrService
      .get(`/api/divisions/${divisionId}/findingTypes`)
      .then((response) => response.data);
  },
  getProjectActivityLogs: (param: ProjectActivityLogApi) => {
    return xhrService
      .post(`/api/divisions/${param.divisionId}/projectActivityLog`, {
        ...param,
      })
      .then((response) => response.data);
  },
};

export default reportApi;
