import { isEmpty } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import InspectionContext from '../../../contexts/InspectionContext';
import { ProjectContext } from '../../../contexts/ProjectContext';
import { withProjectContext } from '../../../hoc/withProjectContext';
import { useClient } from '../../../hooks/client';
import { getInspectionTypeLabel } from '../../../utils';
import NavTabs from '../../Shared/NavTabs';
import Loading from '../../Shared/ResourceIndex/Loading';
import InspectionCertification from '../Certification/InspectionCertification';
import InspectionQuestions from '../Details/InspectionQuestions';
import Findings from '../Findings/Findings';
import InspectionHeader from '../Header/InspectionHeader';
import InspectionMap from '../Map/InspectionMap';
import InspectionPrint from './InspectionPrint';
import SiteMapPrint from './SiteMapPrint';
import AppContext from '../../../contexts/AppContext';
import { AppDivisionContext } from '../../../contexts/AppDivisionContext';
import commentableApi from '../../../utils/api/commentable';

function InspectionDetailUI() {
  const projectContext = useContext(ProjectContext)
  const inspectionContext = useContext(InspectionContext)
  const appContext = useContext(AppContext);
  const history = useHistory();
  const appDivisionContext = useContext(AppDivisionContext);
  const match = useRouteMatch<{ id: string }>();
  const [modal, setModal] = useState<null | 'print' | 'siteMapPrint'>(null);
  const [showModal, setShowModal] = useState<string>('');
  const [initialNotes, setInitialNotes] = useState<any>(null);
  const isPublic = match.path.startsWith("/public")

  const clientQuery = projectContext.project ? useClient(projectContext.project.client_id) : null;

  const userTenantId = appContext && appContext.state && appContext.state.user && appContext.state.user.tenantId
  const clientTenantId = projectContext && projectContext.project && projectContext.project.client && projectContext.project.client.tenant_id

  useEffect(() => {
    if(!userTenantId || !clientTenantId){
      return
    }
    else if(userTenantId !== clientTenantId){
      history.push(appDivisionContext.getPath('/projects'));
    }
  },[userTenantId, clientTenantId])

  useEffect(()=>{
    if(projectContext.project?.external_project){
      history.push(`/projects/${projectContext.project.id}/inspections`)
    }
    updateInitialNotes();
  },[]);

  const handleAddToReport = () => {
    const weatherDatas = JSON.parse(localStorage.getItem('weather' + projectContext.project?.id) || '[]');

    let weatherDataFormatted = 'NOAA Forecast: \n';

    if (weatherDatas) {
      for (let i = 0; i < weatherDatas.length; i++) {
        weatherDataFormatted += `${new Date(weatherDatas[i].time).getMonth() + 1}/${new Date(weatherDatas[i].time).getDate()}/${new Date(weatherDatas[i].time).getFullYear()}: ${weatherDatas[i].detailedForecast} \n`;
      }
    }

    return commentableApi
      .create('inspections', inspectionContext.inspection.id, {
        note: { content: weatherDataFormatted },
      })
      .then(() => {
        setShowModal('success');
        setTimeout(() => {
          setShowModal('');
          updateInitialNotes();
        }, 2000);
      });
  };

  const updateInitialNotes = () => {
    commentableApi.index("inspections", inspectionContext.inspection.id).then(notes => {
      setInitialNotes(notes);
    });
  }

  if (
    !projectContext.project ||
    !inspectionContext.inspection ||
    isEmpty(inspectionContext.inspection)
  ) {
    return (
      <section className='inspection-detail'>
        <Loading what='inspection details' showLoadingText />
      </section>
    );
  }

  useEffect(()=>{
    if(projectContext.project?.external_project){
      history.push(`/projects/${projectContext.project.id}/inspections`)
    }
  },[]);

  return (
    <section className='inspection-detail'>
      <InspectionHeader
        title={getInspectionTypeLabel(inspectionContext.inspection)}
        clientName={clientQuery && clientQuery.data ? clientQuery.data.name : null}
        customTab={clientQuery && clientQuery.data ? clientQuery.data.custom_tab : null}
        projectName={projectContext.projectName}
        enablePrint
        handlePrint={() => setModal('print')}
        handleSiteMapPrint={() => setModal('siteMapPrint')}
        inspectionNumber={inspectionContext.inspection.number}
        inspectionId={inspectionContext.inspection.id}
        handleAddToReport={handleAddToReport}
        showModal={showModal}
      />

      <NavTabs
        tabs={[
          {
            to: '/questionnaire',
            label: 'Inspection Questions',
            Component: InspectionQuestions,
          },
          {
            label: 'Findings',
            to: '/findings',
            showTab: (func) => func('read', 'Findings') || isPublic,
            Component: Findings,
          },
          {
            label: 'Map',
            to: '/map',
            Component: InspectionMap,
          },
          {
            label: 'Certification',
            to: '/certification',
            showTab: (func) =>
              func('read', 'Certify') || func('read', 'Sign Off'),
            Component: InspectionCertification,
            getProps: () => ({ initialNotes }),
          },
        ]}
        url={match.url}
      />
      {/* load modals! */}
      {modal === 'print' ? (
        <InspectionPrint onClose={() => setModal(null)} />
      ) : null}
      {modal === 'siteMapPrint' ? (
        <SiteMapPrint onClose={() => setModal(null)} />
      ) : null}
    </section>
  );
}

function InspectionDetail() {
  return (
      <InspectionDetailUI />
  )
}


export default withProjectContext(InspectionDetail);
