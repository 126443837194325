import {
  CertFormSchema,
  CertSignature,
  getCertFormSchema,
  SignatureGroupOptions,
  SignatureGroupType,
  SignatureLine,
} from "@sw-sw/lib-certification";
import { FormContext, FormSchemaFields } from "@sw-sw/lib-form";
import { find } from "lodash";
import React, { Dispatch, Fragment, useContext } from "react";
import RolesContext from "../../../contexts/RolesContext";
import SignatureCheckbox from "./SignatureCheckbox";

function CertificationForm({
  line,
  options = { enabled: true, errors: [] },
  userData = [],
  isCcr: ccr = false,
  isPe: pe = false,
  minCertificationDate: minDate = new Date(),
  onSave,
  readOnly = false,
}: {
  line: SignatureLine;
  type: SignatureGroupType;
  options?: SignatureGroupOptions;
  userData?: CertSignature[];
  isCcr?: boolean;
  isPe?: boolean;
  minCertificationDate?: Date;
  onSave: Dispatch<{
    lineType: string;
    formData: CertFormSchema;
  }>;
  readOnly?: boolean;
}) {
  const formContext = useContext(FormContext);
  const permCheck = useContext(RolesContext).userHasPermission;
  const isPublic = window.location.pathname.startsWith("/public")

  if (isPublic) {
    if (formContext.value.compliance_userName === "Public User") {
      formContext.set("compliance_userName", "")
      formContext.set("compliance_userRole", "")
      formContext.set("compliance_date", "")
    }
    if (formContext.value.inspection_userName === "Public User") {
      formContext.set("inspection_userName", "")
      formContext.set("inspection_userRole", "")
      formContext.set("inspection_date", "")
    }

  }
  // const restrictedRoles = ["Regulator", "Regulator - With Photos", "COA Regulator"]

  return (
    <Fragment>
      <FormSchemaFields
        className="pure-u pure-u-lg-2-3 pure-u-md-1-2 pure-u-1 insp-form-row"
        formData={formContext.value}
        onChange={formContext.set}
        schema={getCertFormSchema({
          line,
          enabled: !readOnly && options.enabled,
          userSignature: find(userData, { lineType: line.type }),
          date: true,
          minDate,
          ccr,
          pe,
        })}
      />
      <SignatureCheckbox
        lineType={line.type}
        enabled={
          options.enabled &&
          // !restrictedRoles.includes(role)&&
          !readOnly &&
          permCheck(
            "all",
            line.type.startsWith("compliance") ? "Certify" : line.type.startsWith("acknowledgement") ? "Acknowledgement" : "Sign Off",
          )
        }
        errors={options.errors}
        userSignatures={userData}
        onSubmit={() =>
          formContext.getTransformedData().then((data: any) =>
            onSave({
              lineType: line.type,
              formData: data,
            }),
          )
        }
        readOnly={
          !permCheck(
            "all",
            line.type.startsWith("compliance") ? "Certify" : line.type.startsWith("acknowledgement") ? "Acknowledgement" : "Sign Off",
          )
        }
      />
    </Fragment>
  );
}

export default CertificationForm;
