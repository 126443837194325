import { useContext, useEffect } from 'react';
import FormModal from '../Shared/form/modal/FormModal';
import { UIControlType } from '@sw-sw/lib-form-control-types';
import { FormContext, FormSchemaFields } from '@sw-sw/lib-form';
import _ from 'lodash';
import React from 'react';
import divisionApi from '../../utils/api/division';
import { AppDivisionContext } from '../../contexts/AppDivisionContext';
import reportApi from '../../utils/api/report';


const getSchema = () => {

    const formContext = useContext(FormContext);

    const clients = formContext.value.clients;

    const schema: any = {
        clientIds: {
            controlType: UIControlType.customTagSelect,
            labelKey: 'name',
            valueKey: 'id',
            isMulti: true,
            openOnFocus: true,
            label: 'Select Client(s)',
            options: clients,
            noOptionMessage: 'No clients found',
            placeholder: 'Search for clients',
            showSelectAll: true,
            showClearAll: true,
            validation: {
                required: true,
            },
        },
        finding_type: {
            label: "Finding Type",
            controlType: UIControlType.select,
            options: formContext.value.findingType,
            className: "finding-mobile",
            showClearAll: true,
        },
        start_date: {
            controlType: UIControlType.date,
            label: 'Min Date',
            style: { flex: '0 1 50%' },
            validation: {
                maxDate: new Date(),
            },
        },
        end_date: {
            controlType: UIControlType.date,
            label: 'Max Date',
            style: { flex: '0 1 50%' },
            validation: {
                minDate: formContext && formContext.value && formContext.value.start_date ? formContext.value.start_date : null,
                maxDate: new Date(),
            },
        },
        archivedProject: {
            label: 'Include Archived Projects',
            controlType: UIControlType.checkbox,
            style: { marginBottom: 0 },
        }
    };

    return schema;
};

const getValue = (findingType: string) => {
    switch (findingType) {
        case 'CA':
            return { value: findingType, label: 'Corrective Action' };

        case 'MI':
            return { value: findingType, label: 'Maintenance Item' };

        case 'A':
            return { value: findingType, label: 'Achievement' };

        default:
            return { value: findingType, label: findingType };
    }
};

const ComplianceReportModal = ({
    setShowModal,
    isEdit
}: {
    setShowModal: Function;
    isEdit: boolean;
}) => {
    const divisionId: any = useContext(AppDivisionContext).appDivisionId;
    const formContext = useContext(FormContext);


    useEffect(() => {
        if (!isEdit) {
            formContext.set('pageNumber', 1)
            formContext.set('rowPerPage', 10)
        }
    }, [])

    useEffect(() => {
        const clientArr: React.SetStateAction<any[]> = [];
        const findingsArr: React.SetStateAction<any[]> = [];

        divisionApi.clients.index(divisionId).then((clients) => {
            clients.forEach((client) => {
                clientArr.push({ id: client.id, name: client.name });
            });
            formContext.set('clients', clientArr)
        });

        reportApi.getFindings(divisionId).then((findings) => {
            findings.findings.forEach((finding) => {
                if (!findingsArr.includes(finding)) {
                    findingsArr.push(finding);
                }
            });
            formContext.set('findingType', findingsArr.map((finding) => getValue(finding)))
        });
    }, []);

    const formatToTimestamp = (dateStr: any) => {
        if (dateStr) {
            const [month, day, year] = dateStr.split('-');
            const jsDate = new Date(`${year}-${month}-${day}T00:00:00Z`);

            return jsDate.toISOString().replace('T', ' ').replace('Z', '+00');
        }

        return dateStr;
    };


    const submit = async () => {
        formContext.set('loading', true)

        const param = {
            divisionId,
            pageNumber: formContext.value.pageNumber,
            rowsPerPage: formContext.value.rowPerPage,
            client: formContext.value.clientIds.map((ele: { id: any; }) => ele.id),
            maxDate: formatToTimestamp(formContext.value.end_date),
            minDate: formatToTimestamp(formContext.value.start_date),
            findings: formContext.value.finding_type,
            showArchived: formContext.value.archivedProject,
        };

        reportApi
            .index(param)
            .then((res: any) => {
                formContext.set('report', res.pageData)
                formContext.set('pagination', res.paginationState)
                formContext.set('flag', true)
                formContext.set('loading', false)
                setShowModal(false)
            })
            .catch((err: any) => {
                formContext.set('error', err.response.status)
                formContext.set('loading', false)
            });


    };

    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <FormModal
            onSubmit={submit}
            onCancel={handleClose}
            modalProps={{
                title: 'Select Filter',
                submitBtnText: 'Save',
            }}
        >
            <section className='project-form'>
                <section className='container'>
                    <FormSchemaFields
                        schema={getSchema()}
                        onChange={formContext.set}
                        formData={formContext.value}
                        initialFormData={formContext.value}
                    />
                </section>
            </section>
        </FormModal>
    );
};

export default ComplianceReportModal;
