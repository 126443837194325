import React from 'react';
import { DropTarget } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import './UploadCompanyLogo.scss';

const imageMimeTypes = ['image/jpeg', 'image/png', 'image/webp'];
const MAX_FILE_SIZE = 1 * 1024 * 1024;

const fileDropTarget = {
  drop(props: any, monitor: any) {
    const item = monitor.getItem();

    if (item && item.files && item.files.length) {
      const file = item.files[0];
      const fileSize = file.size;
      if (imageMimeTypes.includes(file.type) && fileSize < MAX_FILE_SIZE) {
        props.onFileDrop(file);
      } else {
        props.onError('Only image files are allowed and file size should be less that 1 MB.');
      }
    }
  },
};

const collect = (connect: any, monitor: any) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop(),
});

const DragDropInputFile = ({ connectDropTarget, isOver, canDrop, onFileDrop, onError, replaceState, handleReplaceCancel, text ='Logo' }: any) => {

  const handleFileChange = (e: any) => {

    const file = e.target.files[0];

    if (file && imageMimeTypes.includes(file.type)) {
      onFileDrop(file);
      onError(null);
    } else if (file) {
      onError('Only image files are allowed!');
    } else {
      onError(null);
    }

  };

  return connectDropTarget(
    <div>
      <div className="file-input-container">

        <div className={`drop-area ${isOver && canDrop ? 'drag-over' : ''}`}>
          <p>Drag & Drop file here</p>
        </div>

        <div className='manual-input-holder'>

          <p className="or-text">OR</p>

          <label className="file-input-label">
            Select File
            <input type="file" className="file-input" onChange={handleFileChange} accept="image/*" />
          </label>

        </div>

      </div>

      {replaceState &&
        <div className='replce-cancel-button'>
          <button className='primary' onClick={handleReplaceCancel}>Cancel Replacing {text}</button>
        </div>
      }
    </div>
  );
}

export default DropTarget(NativeTypes.FILE, fileDropTarget, collect)(DragDropInputFile);