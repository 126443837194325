import React, { useState, useContext, useEffect } from 'react';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import DragDropInputFile from './DragDropInputFile';
import ToggleSmall from '../Shared/Toggle/ToggleSmall';
import tenantApi from '../../utils/api/tenant';
import AppContext from '../../contexts/AppContext';
import { toast } from 'react-toastify';
import { isBoolean } from 'lodash';
import ConfirmationModal from '../Shared/ConfirmationModal/ConfirmationModal';
import { useLogo } from '../../contexts/AppLogoContext';


const FileSelectionConfirmation = ({ selectedFile, setSelectedFile, tenantId, busy, setBusy, setShowUploadInput, replaceState, setReplceState }: any) => {

  const { refetchLogo, setAppLogo } = useLogo();

  const uploadLogo = () => {

    toast("Uploading Files!");
    setBusy(true);

    if (selectedFile) {

      const formData: any = new FormData();
      formData.append(`file`, selectedFile);
      formData.append(`tenant_id`, tenantId);

      if (replaceState) {
        formData.append(`is_replaced`, replaceState);
      }

      tenantApi.whitelist.uploadTenantLogo({ tenantId, formData }).then((data) => {

        setBusy(false);
        if (data && data.success === true) {
          setAppLogo(null);
          toast.success("Files Uploaded Successfully!");
          refetchLogo(tenantId);
          setShowUploadInput(false);
        } else {
          toast.error("Something went wrong, files not uploaded!");
        }
        setSelectedFile(null);
        setReplceState(false);

      }).catch((e) => {
        console.log(e)
        setSelectedFile(null)
        setReplceState(false)
      });

    } else {

      setBusy(false);
      toast.error("Something went wrong, files not uploaded!");
      setSelectedFile(null)
      setReplceState(false)

    }

  }

  const cancelUpload = () => {
    setSelectedFile(null)
  }

  return (
    <div className='file-selection-confirm'>
      <p><b>Are you sure want to upload?</b></p>
      <p>File: {selectedFile.name}</p>
      <button className='primary' onClick={() => uploadLogo()} disabled={busy} >Upload</button>
      <button onClick={() => cancelUpload()}>Cancel</button>
    </div>
  )
}

const UploadedLogo = ({ imgSrc, handleDelete, handleReplace }: { imgSrc: string, handleDelete: any, handleReplace: any }) => {
  
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  
  return (
    <div className='uploaded-img-container'>
      <div className='image-holder'>
        <img src={imgSrc} alt="" width={`100%`} />
      </div>
      <div className='actionBtn-holder'>
        <button className='primary' onClick={handleReplace}>Replace</button>
        <button className='danger' onClick={()=>setShowConfirmationModal(true)}>Delete</button>
      </div>

      {
        showConfirmationModal &&
        <ConfirmationModal
          show={showConfirmationModal}
          handleClose={() => setShowConfirmationModal(false)}
          handleConfirm={() => {
            handleDelete();
          }}
          title="Are you sure you want to delete this logo?"
          subTitle="This can not be undone."
          buttonText="Delete Logo"
        />
      }
    </div>
  )
}

const UploadCompanyLogo = () => {
  const appStore = useContext(AppContext);
  const { appLogo, isCustomLogo, refetchLogo, setAppLogo } = useLogo();
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [switchCompanyLogo, setSwitchCompanyLogo] = useState<boolean>();
  const [addLogoFeature, setAddLogoFeature] = useState<any>();
  const [busy, setBusy] = useState<boolean>(false);
  const [showUploadInput, setShowUploadInput] = useState<boolean>(true);
  const [replaceState, setReplceState] = useState<boolean>(false);
  const [showAllUploadSection, setShowAllUploadSection] = useState<boolean>(true);

  const user = appStore.get("user");

  const handleFileDrop = (file: any) => {
    setSelectedFile(file);
    setErrorMessage(null);
  };

  const handleError = (message: string) => {
    setErrorMessage(message);
  };

  const handleDeleteLogo = () => {
    setBusy(true);
    tenantApi.whitelist.deleteCompanyLogo(user.tenantId).then((data) => {
      if (data && data.success === true) {
        setAppLogo(null);
        toast.success("Company Logo Deleted Successfully!");
        setShowUploadInput(true);
        refetchLogo(user.tenantId);
        setBusy(false);
      } else {
        toast.error("Something went wrong while deleting company logo!");
        setBusy(false);
      }
    }).catch(() => {
      toast.error("Something went wrong while deleting company logo!");
      setBusy(false);
    })
  }

  const handleReplaceLogo = () => {
    setShowUploadInput(true);
    setReplceState(true);
  }

  const handleReplaceCancel = () => {
    setShowUploadInput(false);
    setReplceState(false);
  }

  const handleLogoSwitch = () => {

    setBusy(true);

    const currentSwitch = !switchCompanyLogo;

    tenantApi.features
      .update(user.tenantId, addLogoFeature.id, currentSwitch)
      .then(() => {
        setAppLogo(null);
        refetchLogo(user.tenantId);
        setSwitchCompanyLogo(currentSwitch);
        toast.success("Logo state updated successfully!");
        setBusy(false);
        if (currentSwitch && !isCustomLogo) {
          setShowAllUploadSection(true);
        } else {
          setShowAllUploadSection(false);
        }
      }).catch(() => {
        setBusy(false);
        toast.error("Something went wrong while switching tenant logo!")
      })

  }

  const loadInitialData = () => {

    if(isCustomLogo){
      setShowUploadInput(false);
    }else{
      setShowUploadInput(true);
    }

    return Promise.all([
      // tenantApi.whitelist.getCompanyLogo(user.tenantId),
      tenantApi.features.index(user.tenantId, 'add_logo')
    ]).then(([featureResponse]) => {

      if (featureResponse && featureResponse.length && isBoolean(featureResponse[0].is_enabled)) {
        setAddLogoFeature(featureResponse[0]);
        setSwitchCompanyLogo(featureResponse[0].is_enabled);
        (featureResponse[0].is_enabled)? setShowAllUploadSection(true) : setShowAllUploadSection(false);
      }

    }).catch(e => {
      console.log(e)
    });

  };

  useEffect(() => {
    loadInitialData();
  }, []);

  return (
    <div className='company-logo-containers'>

      <div className="logo-toggle-holder">
        <h3 style={{ margin: '0' }}> Use Company Logo </h3>
        <ToggleSmall
          isChecked={switchCompanyLogo}
          disabled={busy}
          handleChange={handleLogoSwitch}
        />
      </div>

      {
        (showAllUploadSection) &&
        <>
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

          {
            (selectedFile && selectedFile.name) ?

              <FileSelectionConfirmation
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                tenantId={user.tenantId}
                busy={busy}
                setBusy={setBusy}
                setShowUploadInput={setShowUploadInput}
                replaceState={replaceState}
                setReplceState={setReplceState}
              />

              :

              (!showUploadInput) ?

                <UploadedLogo imgSrc={appLogo!} handleDelete={handleDeleteLogo} handleReplace={handleReplaceLogo} />

                :

                <DragDropInputFile
                  replaceState={replaceState}
                  selectedFile={selectedFile}
                  onFileDrop={handleFileDrop}
                  onError={handleError}
                  handleReplaceCancel={handleReplaceCancel}
                />

          }
        </>
      }

    </div>
  );
};

export default DragDropContext(HTML5Backend)(UploadCompanyLogo);
