import React, { Fragment, useState, useContext, useEffect } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { shape, string, bool, func } from "prop-types";
import classnames from "classnames";
import AppContext from '../../contexts/AppContext';

import Navigation from "./Navigation";
import { Modal } from "@sw-sw/lib-ui";
import tenantApi from '../../utils/api/tenant';
import { useLogo } from "../../contexts/AppLogoContext";

/**
 * Default application layout, provides a sidebar and content area
 *
 * User should be logged in
 */

const ModalNav = props => {

  const { appLogo } = useLogo();

  return (
    <Fragment>
      <aside className={classnames("nav-menu", { open: props.open })}>
        <header>
          <FontAwesomeIcon
            icon={faTimes}
            className="nav-icon pointer"
            onClick={props.handleClose}
            title="Close menu"
          />

          <Link to="/dashboard" className="modal-home-link" title="Home">
            <img src={appLogo} alt="logo" className="nav-icon" />
          </Link>
        </header>

        <Navigation match={props.match} isHamburger />
      </aside>

      <Modal
        show={props.open}
        classes="menu-backdrop"
        handleClose={props.handleClose}
        dismissOnBgClick
        dismissOnEsc
      />
    </Fragment>
  );
};

ModalNav.propTypes = {
  match: shape({
    path: string.isRequired,
  }).isRequired,
  open: bool.isRequired,
  handleClose: func.isRequired,
};

export default ModalNav;
