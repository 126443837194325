import { FormContext, FormHelpText, FormSchemaFields } from "@sw-sw/lib-form";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect } from "react";
import { fieldTmpl } from "./UserStepOne";
import { UIControlType } from "@sw-sw/lib-form-control-types";

/**
 * Map used to generate the right input label
 *
 * key = name of a role
 * value = title of the role's manager
 *
 * @see {cms-backend/services/addUserService.allowedManagerManageeRoles}
 */
const roleNameToManagerTitle = {
  Inspector: "Managers",
  "Area / Assistant Manager": "Regional Manager",
};

const getProjectOptions = (availableClients, selectedClients) => {
  availableClients.sort((a, b) => a.name.localeCompare(b.name))
  if (selectedClients) {
    const selectedClientIds = selectedClients.map(client => client.id);
    const clients = availableClients.filter(ac =>
      selectedClientIds.includes(ac.id),
    );

    return clients.reduce((acc, client) => acc.concat(client.projects), []);
  }

  return [];
};

const getSchema = (roleFormData, selectedRoleName) => {
  const formContext = useContext(FormContext);

  const sch = {};

  if (roleFormData.clients) {
    sch.clients = {
      ...fieldTmpl,
      label: "Clients",
      controlType: UIControlType.customTagSelect,
      options: roleFormData.clients,
      noOptionMessage: "No clients found",
      placeholder: "Search for clients",
      showClearAll: true,
    };
  }

  if (getProjectOptions(roleFormData.clients, formContext.value.clients)) {
    sch.projects = {
      ...fieldTmpl,
      label: "Projects",
      controlType: UIControlType.customTagSelect,
      options: getProjectOptions(
        roleFormData.clients,
        formContext.value.clients,
      ),
      noOptionMessage: "No projects found",
      placeholder: "Search for projects",
      menuPlacement: "top",
      showSelectAll: true,
      showClearAll: true,
    };
  }
  if (selectedRoleName && roleNameToManagerTitle[selectedRoleName]) {
    sch.managerUserIds = {
      label: `Assign to ${roleNameToManagerTitle[selectedRoleName]}`,
      controlType: UIControlType.customTagSelect,
      options: roleFormData.managers,
      isMulti: true,
      openOnFocus: true,
      labelKey: "name",
      valueKey: "id",
    };
  }

  return sch;
};

const UserStepTwo = ({ formOpts }) => {
  const formContext = useContext(FormContext);

  const getRoleName = useCallback(() => {
    const role = formOpts.roles.find(v => v.id === formContext.value.roleId);

    if (role) {
      return role.name;
    }

    return formContext.value.roleName;
  }, [formOpts.roles.length, formContext.value.roleId]);

  useEffect(() => {
    const roleName = getRoleName();

    if (!Object.keys(roleNameToManagerTitle).includes(roleName)) {
      formContext.set("managerUserIds", null);
    }
  }, [formContext.value.roleId]);

  useEffect(() => {
    if (!formContext.value.clients || !formContext.value.clients.length) {
      formContext.set("projects", []);
    }
  }, []);

  return (
    <React.Fragment>
      <FormSchemaFields
        className="findings-form"
        schema={getSchema(formOpts, getRoleName())}
        formData={formContext.value}
        onChange={formContext.replace}
      />
      {getRoleName() === "Admin" && (
        <FormHelpText content="Add any projects you would like to receive notifications from" />
      )}
    </React.Fragment>
  );
};

const optionShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
});

UserStepTwo.propTypes = {
  formOpts: PropTypes.shape({
    projects: PropTypes.arrayOf(optionShape).isRequired,
    clients: PropTypes.arrayOf(optionShape).isRequired,
    managers: PropTypes.arrayOf(optionShape),
  }).isRequired,
};

export default UserStepTwo;
