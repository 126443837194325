import React, { PropsWithChildren, ReactElement } from "react";
import { useLogo } from "../../contexts/AppLogoContext";
import SWLogo from '../../images/SW-Logo-White.svg';

/**
 * Narrow & centered
 *
 * Used for login and recovery
 */
const NarrowLayout: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const { appLogo, isCustomLogo } = useLogo();
  return (
    <div className="container App">
      <div className="layout--narrow">
        <div className="login-logo-container">
          <img src={appLogo!} className="app-logo" alt="logo" />
        </div>

        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child as ReactElement, {  }); // pass props in this braces if needed. Its empty because props are move to context.
          }
          return child;
        })}
      </div>
      {(isCustomLogo) &&
        <div className="powerdby-logo-container">
          {/* <img src={powerdByLogo} className="" alt="logo" /> */}
          <span className="powerd-by-logo-span">Powerd By <img src={SWLogo} alt="Powerd By Logo" /></span>
        </div>
      }
    </div>
  );
};

export default NarrowLayout;
