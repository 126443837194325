import React, { useContext, useEffect, useState } from 'react';
import { AppDivisionContext } from '../../contexts/AppDivisionContext';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Tooltip from '@mui/material/Tooltip';
import reportApi from '../../utils/api/report';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import Loading from '../Shared/ResourceIndex/Loading';
import { Typography, Checkbox } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Link } from 'react-router-dom';
import { SchemaFieldsWithContextUI, FormContextProvider } from "@sw-sw/lib-form";
import { UIControlType } from "@sw-sw/lib-form-control-types";
import ExcelJs from 'exceljs';
import { jsPDF } from "jspdf"
import autoTable from "jspdf-autotable"
import "jspdf-autotable"
import { FormModal } from "../Shared/form";
import ComplianceReportModal from './ComplianceReportModal';
import { FormContext } from '@sw-sw/lib-form';

const staticRowName = [
  {
    id: 0,
    label: 'Project Name',
    value: 'projectName',
    isEnable: true,
    totalPlaceHolder: true,
  },
  {
    id: 1,
    label: 'Client Name',
    value: 'clientName',
    isEnable: false,
    showInOptions: true,
  },
  {
    id: 2,
    label: 'Total Inspection',
    value: 'inspectionCount',
    isEnable: true,
    showInOptions: true,
    hasTotal: true,
  },
  {
    id: 3,
    label: "Missed Inspection",
    value: "missedIsp",
    isEnable: true,
    showInOptions: true,
    hasTotal: true,
  },
  {
    id: 4,
    label: 'Total Findings',
    value: 'findingsCount',
    isEnable: true,
    showInOptions: true,
    hasTotal: true,
  },
  {
    id: 5,
    label: 'Avg. Findings per Inspection',
    value: 'avgFindings',
    isEnable: true,
    showInOptions: true,
    hasAvg: true,
    superSet: 'AVG',
    decimal: true,
  },
  {
    id: 6,
    label: 'Avg. Days to Correct',
    value: 'avgFindingDays',
    isEnable: true,
    showInOptions: true,
    hasAvg: true,
    superSet: 'AVG',
    decimal: true,
  },
  {
    id: 7,
    label: 'Max Days to Correct',
    value: 'maxDay',
    isEnable: true,
    showInOptions: true,
    superSet: 'MAX',
  },
  {
    id: 8,
    label: 'Open Findings',
    value: 'openFindings',
    isEnable: true,
    showInOptions: true,
    hasTotal: true,
    hasPercent: true,
    percentVariance: 'findingsCount',
  },
  {
    id: 9,
    label: 'Not Completed On-Time',
    value: 'notCompleteInExpectedDays',
    tooltip: 'Number of findings that took longer than 7-days to complete',
    isEnable: true,
    showInOptions: true,
    hasTotal: true,
    hasPercent: true,
    percentVariance: 'findingsCount',
  },
  {
    id: 10,
    label: 'Acknowledgement Certification',
    value: 'ackCount',
    tooltip:
      'Number of inspections where the acknowledgment statement was not signed within 2 days of the date of inspection',
    isEnable: true,
    showInOptions: true,
    hasTotal: true,
    hasPercent: true,
    percentVariance: 'inspectionCount',
  },
  {
    id: 11,
    label: 'Compliance Certification',
    value: 'complianceCount',
    tooltip:
      'Number of inpsection where the compliance certification has not been signed',
    isEnable: true,
    showInOptions: true,
    hasTotal: true,
    hasPercent: true,
    percentVariance: 'inspectionCount',
  }
];

const downloadOptions = [
  { name: 'PDF' },
  { name: 'Excel' },
]

function getSchema() {
  return {
    format: {
      label: 'Format',
      controlType: UIControlType.select,
      placeholder: "Select",
      "aria-label": "export-type",
      autoComplete: "export-type",
      options: downloadOptions,
      labelKey: 'name',
      valueKey: 'name',
      style: { flex: '1 1 50%' },
      validation: {
        required: true,
      },
    },
  }
}

const getInitialFormData = () => {
  return {
    format: "",
  };
}

export const ComplianceReport = () => {
  const divisionId: any = useContext(AppDivisionContext).appDivisionId;
  const formContext: any = useContext(FormContext)
  const [search, setSearch] = useState<string | undefined>();
  const theme = useTheme();
  const [rowName, setRowName] = useState<any>(staticRowName);
  const [open, setOpen] = useState<boolean>(false);
  const [colSpan, setColSpan] = useState<number>(7);
  const [colTotalSpan, setColTotalSpan] = useState<number>(1);
  const [error, setError] = useState<number>(0);
  const [showUpArrow, setShowUpArrow] = useState<boolean>(false);
  const [showDownArrow, setShowDownArrow] = useState<boolean>(false);
  const [showMidWayDownArrow, setShowMidWayDownArrow] =
    useState<boolean>(false);
  const [isDownloadModal, setIsDownloadModal] = useState(false)

  const [showModal, setShowModal] = useState<boolean>(true)
  const [showEditModal, setShowEditModal] = useState<boolean>(false)

  const { flag, pagination, pageNumber, report, rowPerPage, loading, clientIds, archivedProject, start_date, end_date, finding_type } = formContext.value

  const getReportData = ({
    divId,
    pageNum,
    searchClient,
    rowsPage,
  }: {
    divId: number,
    pageNum: number,
    searchClient: string | undefined,
    rowsPage: number,
  }) => {
    const param = {
      divisionId: divId,
      pageNumber: pageNum,
      searchQuery: searchClient,
      rowsPerPage: rowsPage,
      client: clientIds.map((ele: any) => ele.id),
      showArchived: archivedProject,
      findings: finding_type
    };

    reportApi
      .index(param)
      .then((res: any) => {
        formContext.set('report', res.pageData)
        formContext.set('pagination', res.paginationState)
        formContext.set('flag', true)
      })
      .catch((err: any) => {
        setError(err.response.status);
        formContext.set('loading', false)
      });
  }

  const TablePaginationActions = () => {
    const { pageSize, numModels } = pagination;

    const handleFirstPageButtonClick = () => {
      formContext.set('pageNumber', 1)
      getReportData({ divId: divisionId, pageNum: 1, searchClient: search, rowsPage: rowPerPage })
    };

    const handleBackButtonClick = () => {
      getReportData({ divId: divisionId, pageNum: pageNumber - 1, searchClient: search, rowsPage: rowPerPage })
      formContext.set('pageNumber', pageNumber - 1)
    };

    const handleNextButtonClick = () => {
      getReportData({ divId: divisionId, pageNum: pageNumber + 1, searchClient: search, rowsPage: rowPerPage })
      formContext.set('pageNumber', pageNumber + 1)
    };

    const handleLastPageButtonClick = () => {
      getReportData({ divId: divisionId, pageNum: Math.ceil(numModels / pageSize), searchClient: search, rowsPage: rowPerPage })
      formContext.set('pageNumber', Math.ceil(numModels / pageSize))
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={pageNumber === 1}
          aria-label='first page'
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={pageNumber === 1}
          aria-label='previous page'
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={pageNumber >= Math.ceil(numModels / pageSize)}
          aria-label='next page'
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={pageNumber >= Math.ceil(numModels / pageSize)}
          aria-label='last page'
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };

  const HandleRowPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    formContext.set('rowPerPage', +event.target.value)
    getReportData({ divId: divisionId, pageNum: pageNumber, searchClient: search, rowsPage: +event.target.value })
  };

  const getTotalRowValue = (totalValue: any, data: any) => {
    rowName.forEach((rowValue: any) => {
      if (rowValue.isEnable) {
        if (
          rowValue.value !== 'projectName' &&
          rowValue.value !== 'clientName'
        ) {
          if (rowValue.decimal) {
            if (rowValue.hasAvg) {

              totalValue[rowValue.value] = totalValue[rowValue.value] ||
                totalValue[rowValue.value] === 0
                ? `${(
                  totalValue[rowValue.value] / data.pageData.length
                ).toFixed(2)} (${rowValue.superSet})`
                : '-'

            }
            else {
              totalValue[rowValue.value] = totalValue[rowValue.value] ||
                totalValue[rowValue.value] === 0
                ? 0
                : '-'
            }
          }
          if (rowValue.superSet && !rowValue.hasAvg) {
            totalValue[rowValue.value] = `${totalValue[rowValue.value]} (${rowValue.superSet})`

          }

        }
      }
    })

    return totalValue
  }

  const getPercentRowValue = (totalValue: any) => {
    rowName.forEach((rowValue: any) => {
      if (rowValue.isEnable) {
        if (rowValue.hasPercent) {
          totalValue[rowValue.value] = totalValue[rowValue.value]
            ? `${(
              (100 * totalValue[rowValue.value]) /
              totalValue[rowValue.percentVariance]
            ).toFixed(2)}%`
            : '-'
        }
      }
    })

    return totalValue
  }

  const getTotalData = (report: any) => {

    const totalValue: any = {
      inspectionCount: 0,
      findingsCount: 0,
      avgFindings: 0,
      avgFindingDays: 0,
      maxDay: 0,
      ackCount: 0,
      complianceCount: 0,
      openFindings: 0,
      notCompleteInExpectedDays: 0,
      missedIsp: 0,
    };

    report.forEach((ele: any) => {
      const keys: string[] = Object.keys(ele);

      keys.forEach((key) => {
        if (key === 'maxDay') {
          totalValue[key] =
            totalValue[key] > ele[key] ? totalValue[key] : ele[key];
        } else {
          totalValue[key] += ele[key];
        }
      });
    });

    return totalValue
  }

  const TotalData = () => {
    const totalValue: any = getTotalData(report)

    return (
      <>
        <TableRow className='compliance-report__total-data'>
          {rowName.map((rowValue: any) => {
            if (rowValue.isEnable) {
              if (
                rowValue.value !== 'projectName' &&
                rowValue.value !== 'clientName'
              ) {
                if (rowValue.decimal) {
                  if (rowValue.hasAvg) {
                    return (
                      <TableCell className='sup-cell' align='center'>
                        <sup>{rowValue.superSet}</sup>
                        {totalValue[rowValue.value] ||
                          totalValue[rowValue.value] === 0
                          ? (
                            totalValue[rowValue.value] / report.length
                          ).toFixed(2)
                          : '-'}
                      </TableCell>
                    );
                  }

                  return (
                    <TableCell align='center'>
                      {totalValue[rowValue.value] ||
                        totalValue[rowValue.value] === 0
                        ? totalValue[rowValue.value].toFixed(2)
                        : '-'}
                    </TableCell>
                  );
                }
                if (rowValue.superSet) {
                  return (
                    <TableCell className='sup-cell' align='center'>
                      <sup>{rowValue.superSet}</sup>
                      {totalValue[rowValue.value]}
                    </TableCell>
                  );
                }

                return (
                  <TableCell align='center'>
                    {totalValue[rowValue.value]}
                  </TableCell>
                );
              }
              if (rowValue.totalPlaceHolder) {
                return (
                  <TableCell colSpan={colTotalSpan} align='center'>
                    Total
                  </TableCell>
                );
              }

              return null;
            }

            return null;
          })}
        </TableRow>
        <TableRow className='compliance-report__percent-variance'>
          <TableCell align='center' colSpan={colSpan}>
            <strong>Percent Variance</strong>
          </TableCell>
          {rowName.map((rowValue: any) => {
            if (rowValue.isEnable) {
              if (rowValue.hasPercent) {
                return (
                  <TableCell align='center'>
                    <strong>
                      {totalValue[rowValue.value]
                        ? `${(
                          (100 * totalValue[rowValue.value]) /
                          totalValue[rowValue.percentVariance]
                        ).toFixed(2)}%`
                        : '-'}
                    </strong>
                  </TableCell>
                );
              }

              return null;
            }

            return null;
          })}
        </TableRow>
      </>
    );
  };

  const handleChangeSearch = (e: any) => {
    setSearch(e.target.value.trim());
    if (search) {
      getReportData({ divId: divisionId, pageNum: pageNumber, searchClient: e.target.value, rowsPage: rowPerPage })
    }
  };

  const onCheck = (ele: any) => {
    const newOption = rowName.map((row: any) => {
      if (ele.id === row.id) {
        row.isEnable = !row.isEnable;

        if (row.isEnable && !row.hasPercent) {
          setColSpan(colSpan + 1);
        }
        if (!row.isEnable && !row.hasPercent) {
          setColSpan(colSpan - 1);
        }

        return row;
      }
      if (ele.value === 'clientName') {
        if (ele.isEnable) {
          setColTotalSpan(colTotalSpan + 1);
        } else {
          setColTotalSpan(colTotalSpan - 1);
        }
      }

      return row;
    });

    setRowName(newOption);
  };

  const handleExcelDownload = async () => {
    const { numModels } = pagination;

    const param = {
      divisionId,
      pageNumber: 1,
      searchQuery: search,
      rowsPerPage: numModels,
      client: clientIds.map((ele: any) => ele.id),
      maxDate: end_date,
      minDate: start_date,
      findings: finding_type,
      showArchived: archivedProject,
    };

    const data: any = await reportApi.index(param)

    let totalValue = getTotalData(data.pageData)

    const workbook = new ExcelJs.Workbook();

    const worksheet = workbook.addWorksheet('Compliance Report');

    worksheet.columns = rowName.filter((row: any) => row.isEnable).map((row: any) => ({ header: row.label, key: row.value, width: 20 }))

    worksheet.addRows(data.pageData)

    totalValue = getTotalRowValue(totalValue, data)

    totalValue.projectName = 'Total'
    totalValue.clientName = ''


    const clientRow = rowName.find((row: any) => row.value === 'clientName')

    const totalColspan = clientRow && clientRow.isEnable ? 'A' + (data.pageData.length + 2) + ':B' + (data.pageData.length + 2) : null

    const totalRow = worksheet.addRow(totalValue);

    if (totalColspan)
      worksheet.mergeCells(totalColspan)

    totalRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'E9E9E9' },
    }
    totalRow.font = { bold: true }
    totalRow.alignment = { vertical: 'middle', horizontal: 'center' }


    totalValue = getPercentRowValue(totalValue)

    const rowsDisabled = rowName.filter((row: any) => !row.isEnable)
    const ro = 'G'.charCodeAt(0) - rowsDisabled.length
    const char = String.fromCharCode(ro)


    totalValue.projectName = 'Percent Variance'
    const percentColspan = rowsDisabled && rowsDisabled.length === 0 ? 'A' + (data.pageData.length + 3) + ':G' + (data.pageData.length + 3) : 'A' + (data.pageData.length + 3) + `:${char}` + (data.pageData.length + 3)

    const percentRow = worksheet.addRow(totalValue);

    if (percentColspan)
      worksheet.mergeCells(percentColspan)

    percentRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'E9E9E9' },
    }
    percentRow.font = { bold: true }
    percentRow.alignment = { vertical: 'middle', horizontal: 'center' }

    worksheet.getRow(1).font = { bold: true };

    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')

      a.href = url
      a.download = `ComplianceReport_${new Date()
        .toJSON()
        .slice(0, 10)}.xlsx`;
      a.click()
      window.URL.revokeObjectURL(url)
    })

  }

  const handlePdfDownload = async () => {
    const { numModels } = pagination;

    const param = {
      divisionId,
      pageNumber: 1,
      searchQuery: search,
      rowsPerPage: numModels,
      client: clientIds.map((ele: any) => ele.id),
      maxDate: end_date,
      minDate: start_date,
      findings: finding_type,
      showArchived: archivedProject,
    };

    const data: any = await reportApi.index(param)

    let totalValue = getTotalData(data.pageData)

    const doc = new jsPDF("l", "mm", "a4", true)
    const body = [...data.pageData]

    totalValue = getTotalRowValue(totalValue, data)
    body.push({ projectName: 'Total', clientName: '', inspectionCount: totalValue.inspectionCount, findingsCount: totalValue.findingsCount, avgFindings: totalValue.avgFindings, avgFindingDays: totalValue.avgFindingDays, maxDay: totalValue.maxDay, openFindings: totalValue.openFindings, notCompleteInExpectedDays: totalValue.notCompleteInExpectedDays, ackCount: totalValue.ackCount, complianceCount: totalValue.complianceCount })

    totalValue = getPercentRowValue(totalValue)
    body.push({ projectName: 'Percent Variance', clientName: '', inspectionCount: '', findingsCount: '', avgFindings: '', avgFindingDays: '', maxDay: '', openFindings: totalValue.openFindings, notCompleteInExpectedDays: totalValue.notCompleteInExpectedDays, ackCount: totalValue.ackCount, complianceCount: totalValue.complianceCount })

    autoTable(doc, {
      columns: rowName.filter((row: any) => row.isEnable).map((row: any) => ({ header: row.label, dataKey: row.value })),
      body: body,
      startY: 20,
      rowPageBreak: 'avoid',
      didParseCell: (data: any) => {
        if (data.section === 'body' && data.row.index === numModels || data.row.index === numModels + 1) {
          data.cell.styles.fontStyle = 'bold'
          data.cell.styles.fillColor = '#E9E9E9'
          data.cell.styles.halign = 'center';
          data.cell.styles.valign = 'middle';

        }
      }
    })

    doc.save(`ComplianceReport_${new Date()
      .toJSON()
      .slice(0, 10)}.pdf`)
  }

  const onDownloadButtonClick = () => {
    if (flag) {
      setIsDownloadModal(true)
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      // If at the top, show down arrow, hide up arrow
      if (scrollTop <= 300) {
        setShowDownArrow(true);
        setShowMidWayDownArrow(false);
        setShowUpArrow(false);
      }
      // If at the bottom, show up arrow, hide down arrow
      else if (scrollTop + windowHeight >= documentHeight) {
        setShowUpArrow(true);
        setShowMidWayDownArrow(false);
        setShowDownArrow(false);
      }
      // If in between, show both arrows
      else {
        setShowUpArrow(true);
        setShowMidWayDownArrow(true);
        setShowDownArrow(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };

  if (loading) {
    return <Loading />;
  }

  if (error === 400) {
    return (
      <div style={{ textAlign: 'center' }}>
        <h3>
          <strong>
            This User role doesnt have the access to the reports page
          </strong>
        </h3>
      </div>
    );
  }

  if (error === 500 || error === 404) {
    return (
      <div style={{ textAlign: 'center' }}>
        <h3>
          <strong>Something went wrong. Please try again later</strong>
        </h3>
      </div>
    );
  }

  return (
    <div className='compliance-report'>
      <div className='compliance-report__menu'>
        {clientIds && clientIds.length ? <div
          className='compliance-report__cog-wrapper'
          onClick={() => setOpen(!open)}
        >
          <FontAwesomeIcon icon={faCog} className='pad-left' />
        </div> : null}

        {showModal && <ComplianceReportModal
          setShowModal={setShowModal}
          isEdit={false}
        />}

        {showEditModal && <ComplianceReportModal
          setShowModal={setShowEditModal}
          isEdit={true}
        />}

        <div
          className='compliance-report__drawer-wrapper'
          style={{ display: open ? 'block' : 'none' }}
        >
          <div className='compliance-report__drawer'>
            <p>Column Setting</p>
            <hr />
            <div className='compliance-report__widget-list'>
              {rowName && rowName.length
                ? rowName.map((row: any) => {
                  if (row.showInOptions) {
                    return (
                      <div className='compliance-report__widget-list-item'>
                        <Checkbox
                          onChange={() => onCheck(row)}
                          checked={row.isEnable}
                        />
                        <Typography variant='h6'>{row.label}</Typography>
                      </div>
                    );
                  }

                  return null;
                })
                : null}
            </div>
          </div>
        </div>
      </div>
      <div className='compliance-report__checkboxed_filter'>
      </div>
      <div className='compliance-report__toolbar'>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <input
              type='text'
              placeholder='Search'
              onChange={handleChangeSearch}
              value={search}
              className='selectClient-input search-control'
              disabled={clientIds && clientIds.length ? false : true}
              style={{ cursor: clientIds && clientIds.length ? "pointer" : "not-allowed" }}
            />
          </Grid>
          <Grid item xs={2}>
            <button className='primary' style={{ marginBottom: '5px' }} onClick={() => setShowEditModal(true)}>
              Filter
            </button>
          </Grid>
          <Grid item xs={2}>
            <button className='primary' style={{ marginBottom: '5px' }} onClick={() => onDownloadButtonClick()}>
              Download
            </button>
          </Grid>
        </Grid>
        {rowPerPage > 10 && (
          <IconButton
            onClick={scrollToBottom}
            style={{
              visibility: showDownArrow ? 'visible' : 'hidden',
              color: 'white',
              width: '40px',
              marginLeft: 'auto',
              borderRadius: '50%',
              top: '100px',
              zIndex: 1000,
            }}
            className='arrow-up-down-sticky'
          >
            <ArrowDownwardIcon />
          </IconButton>
        )}
      </div>
      <span>
        {showMidWayDownArrow && rowPerPage > 10 && (
          <IconButton
            onClick={scrollToBottom}
            style={{
              color: 'white',
              top: '100px',
              zIndex: 1000,
            }}
            className='arrow-up-down-sticky'
          >
            <ArrowDownwardIcon />
          </IconButton>
        )}
      </span>
      {flag && (
        <>
          <TableContainer
            component={Paper}
            className='compliance-report__table-container'
          >
            <Table sx={{ minWidth: 650 }} aria-label='caption table'>
              <TableHead>
                <TableRow>
                  {rowName.map((rowTitle: any) => {
                    if (rowTitle.isEnable) {
                      if (rowTitle.tooltip) {
                        return (
                          <Tooltip
                            title={rowTitle.tooltip}
                            placement='top'
                            arrow
                          >
                            <TableCell align='center'>
                              <strong>{rowTitle.label}</strong>
                            </TableCell>
                          </Tooltip>
                        );
                      }

                      return (
                        <TableCell align='center'>
                          <strong>{rowTitle.label}</strong>
                        </TableCell>
                      );
                    }

                    return null;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {report.length ? (
                  report.map((row: { [x: string]: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; customTab: boolean; id: any; }, index: React.Key | null | undefined) => (
                    <TableRow key={index}>
                      {rowName.map((rowValue: any) => {
                        if (rowValue.isEnable) {
                          if (
                            rowValue.value !== 'projectName' &&
                            rowValue.value !== 'clientName'
                          ) {
                            return (
                              <TableCell align='center'>
                                {row[rowValue.value] !== null
                                  ? row[rowValue.value]
                                  : '-'}
                              </TableCell>
                            );
                          }

                          return (
                            <TableCell>
                              <Link
                                className='compliance-report__link-color'
                                to={`${row.customTab === true
                                  ? `/divisions/${divisionId}/projects/${row.id}/project-docs`
                                  : `/divisions/${divisionId}/projects/${row.id}/inspections`
                                  }`}
                              >
                                {row[rowValue.value]}
                              </Link>
                            </TableCell>
                          );
                        }

                        return null;
                      })}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={staticRowName.length} align='center'>
                      <strong>No data found</strong>
                    </TableCell>
                  </TableRow>
                )}
                <TotalData />
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            colSpan={3}
            rowsPerPageOptions={[
              10,
              20,
              30,
              50,
              { value: pagination.numModels, label: 'All' },
            ]}
            component='div'
            count={pagination.numModels}
            rowsPerPage={rowPerPage}
            page={pageNumber - 1}
            onPageChange={() => console.log('demo')}
            onRowsPerPageChange={HandleRowPerPage}
            ActionsComponent={TablePaginationActions}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
          />
          {showUpArrow && rowPerPage > 10 && (
            <IconButton
              onClick={scrollToTop}
              className='arrow-up-down-sticky'
              style={{ color: 'white' }}
            >
              <ArrowUpwardIcon />
            </IconButton>
          )}
        </>
      )}

      {isDownloadModal &&
        <FormContextProvider>
          <FormModal
            modalProps={{
              title: 'Print Compliance Report',
              submitBtnText: "Download",
            }}
            onSubmit={(formData) => {
              if (formData.format === 'Excel') {
                handleExcelDownload()
                setIsDownloadModal(false)
              }

              if (formData.format === 'PDF') {
                handlePdfDownload()
                setIsDownloadModal(false)
              }

              return Promise.resolve()
            }}
            onCancel={() => setIsDownloadModal(false)}
          >
            <SchemaFieldsWithContextUI
              schema={getSchema()}
              initialFormData={getInitialFormData()}
            />
          </FormModal>
        </FormContextProvider>
      }
    </div>
  );
};
