import React, { useEffect, useState,useContext } from 'react';
import { FormModal } from '../../Shared/form';
import styles from './Permissions.module.scss';
import Toggle from '../../Shared/Toggle/toggle';
import notificationApi from '../../../utils/api/notifications';
import TenantFeatureContext from '../../../contexts/TenantContext'

export default function Permissions(props) {
    const [notifications, setNotifications] = useState([])
    const [toggleValue, setToggleValue] = useState({})
    const [notificationTypes, setNotificationTypes] = useState([])
    const { tenantHasFeature } = useContext(TenantFeatureContext)
    const obj = {}

    useEffect(() => {
        (async () => {
            setNotifications(props.notifications)
            for (const notification of props.notifications) {
                if (notification.is_enabled.length > 0) {
                    obj[notification.id] = true
                }
                else {
                    obj[notification.id] = false
                }
            }
            setToggleValue(obj)
        })();
    }, [])

    const handleSubmit = async () => {
        const arrayDetails = []

        notificationTypes.forEach((e) => {
            if (e.toggle) {
                arrayDetails.push({
                    isEnabled: [
                        "in-app",
                        "email"
                    ],
                    typeId: e.notificationId
                })
            }
            else {
                arrayDetails.push({
                    isEnabled: [],
                    typeId: e.notificationId
                })
            }
        })
        notificationApi.blukUpdateTypeSetting(props.userId, arrayDetails).then((data) => {
            props.refetch()
            props.hideModal()
        })
    }

    const handleNotificationTypes = (notificationId, toggle) => {
        let match = false
        const elementsArray = notificationTypes.length ? notificationTypes.map((ele) => {
            if (notificationId === ele.notificationId) {
                match = true

                return null
            }

            return ele
        }) : []
        const filteredNotifications = elementsArray.filter(e => e)

        if (!match) {
            filteredNotifications.push({ notificationId: notificationId, toggle: toggle })
        }

        return filteredNotifications
    }

    return (
        <FormModal
            modalProps={{
                title: 'Permissions'
            }}
            onSubmit={handleSubmit}
            onCancel={props.hideModal}
        >
            <div>
                <h3 className={styles.permissionsModalHeader}>Notifications</h3>
                <div className={styles.rowContainer}>
                    {notifications && notifications.map((notification) => {
                        return (
                            <div className={styles.singleRow} >
                                <h4>{notification.name}</h4>
                                <Toggle isChecked={toggleValue[notification.id] && tenantHasFeature('Precipitation Notification')}
                                    disabled={notification.name==='Daily Precipitation Notification' && !tenantHasFeature('Precipitation Notification')}
                                    handleChange={() => setToggleValue((state) => {
                                    setNotificationTypes(handleNotificationTypes(notification.id, !toggleValue[notification.id]))

                                    return {
                                        ...state,
                                        [notification.id]: !toggleValue[notification.id]
                                    }

                                })} id={notification.id} />
                            </div>
                        )
                    })}


                </div>
            </div>
        </FormModal>
    )
}
