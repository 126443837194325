import React, { useState, useEffect, useContext } from 'react';
import AppContext from '../../../contexts/AppContext';
import RolesContext from '../../../contexts/RolesContext';
import ToggleSmall from '../../Shared/Toggle/ToggleSmall';
import tenantApi from '../../../utils/api/tenant';
import { toast } from 'react-toastify';
import { useTenantFeature } from '../../../hooks/features';
import { Tooltip } from '@mui/material';
import { useLogo } from '../../../contexts/AppLogoContext';

interface FeaturesFormat {
  feature: {
    feature_type: string;
    name: string;
    tooltip: string;
  };
  id: any;
  is_enabled: boolean;
}

const Feature = (
  title: string,
  featuresList: FeaturesFormat[],
  handleToggle: (id: number, currentInclude: boolean, feature_type?:string) => void,
) => {
  const [collapse, setCollapse] = useState(false);
  const permCheck = useContext(RolesContext).userHasPermission;

  return (
    <div className='settings-tenant-main-container'>
      <div
        className='settings-main-tenant-text'
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '30px',
          cursor: 'pointer',
        }}
        onClick={() => setCollapse(!collapse)}
      >
        <h3>{title}</h3>
        <i
          className={collapse ? 'fa fa-chevron-down' : 'fa fa-chevron-up'}
          aria-hidden='true'
        />
      </div>
      <hr></hr>
      <div
        className={
          collapse ? 'administration-hidden' : 'administration-visible'
        }
        style={{ marginBottom: '20px' }}
      >
        {featuresList.map((ele) => {
          return ele.feature.tooltip ? (
            <div className='features-table-body'>
              <Tooltip title={ele.feature.tooltip} placement='top' arrow>
                <h3>{ele.feature.name}</h3>
              </Tooltip>
              <h3 className='features-table-body-toggle'>
                <ToggleSmall
                  isChecked={ele.is_enabled}
                  handleChange={() => handleToggle(ele.id, ele.is_enabled)}
                  disabled={!permCheck('all', 'Administration Features')}
                />
              </h3>
            </div>
          ) : (
            <div className='features-table-body'>
              <h3>{ele.feature.name}</h3>
              <h3 className='features-table-body-toggle'>
                <ToggleSmall
                  isChecked={ele.is_enabled}
                  handleChange={() => handleToggle(ele.id, ele.is_enabled, ele.feature.feature_type)}
                  disabled={!permCheck('all', 'Administration Features')}
                />
              </h3>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const AllFeatures = (props:any) => {
  const [featuresList, setfeaturesList] = useState<FeaturesFormat[]>([]);
  const appContext = useContext(AppContext);
  const tenantFeatureQuery = useTenantFeature();
  const { refetchLogo } = useLogo();

  const userTenantId =
    appContext &&
    appContext.state &&
    appContext.state.user &&
    appContext.state.user.tenantId;

  const loadInitialData = () => {
    tenantApi.features.index(userTenantId).then((res) => {
      setfeaturesList(res);
    });
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  const handleToggle = async (id: number, currentInclude: boolean, feature_type:string|null=null) => {
    const updatedFeaturesList = featuresList.map((ele) => {
      if (ele.id === id) {
        return {
          feature: {
            feature_type: ele.feature.feature_type,
            name: ele.feature.name,
            tooltip: ele.feature.tooltip,
          },
          id: id,
          is_enabled: !currentInclude,
        };
      }

      return ele;
    });

    setfeaturesList(updatedFeaturesList);
    tenantApi.features
      .update(userTenantId, id, !currentInclude)
      .then(() => {
        tenantFeatureQuery.refetch();
        if(feature_type === "white_label"){
          refetchLogo(userTenantId)
          props.setIsWhiteLabel(!currentInclude);
        }
        toast.success(
          `Feature ${
            currentInclude === false ? 'Enabled' : 'Disabled'
          } Successfully!`,
        );
      })
      .catch(() => {
        toast.error('Error updating feature');

        const errorhandledFeaturesList = featuresList.map((ele) => {
          if (ele.id === id) {
            return {
              feature: {
                feature_type: ele.feature.feature_type,
                name: ele.feature.name,
                tooltip: ele.feature.tooltip,
              },
              id: id,
              is_enabled: currentInclude,
            };
          }

          return ele;
        });

        setfeaturesList(errorhandledFeaturesList);
      });
  };

  return (
    <div>
      {Feature(
        'White Label',
        featuresList.filter(
          (ele) => ele.feature.feature_type === 'white_label',
        ),
        handleToggle,
      )}
      {Feature(
        'Precipitation Tracking',
        featuresList.filter(
          (ele) => ele.feature.feature_type === 'precipitation_tracking',
        ),
        handleToggle,
      )}
      {Feature(
        'Permission Group',
        featuresList.filter(
          (ele) => ele.feature.feature_type === 'permission_group',
        ),
        handleToggle,
      )}
      {Feature(
        'Site Map Editor',
        featuresList.filter(
          (ele) => ele.feature.feature_type === 'site_map_editor',
        ),
        handleToggle,
      )}
      {Feature(
        'Reporting',
        featuresList.filter((ele) => ele.feature.feature_type === 'reporting'),
        handleToggle,
      )}
      {Feature(
        'Project / Findings',
        featuresList.filter(
          (ele) => ele.feature.feature_type === 'projects_findings',
        ),
        handleToggle,
      )}
      {Feature(
        'System Settings',
        featuresList.filter(
          (ele) => ele.feature.feature_type === 'system_settings',
        ),
        handleToggle,
      )}
    </div>
  );
};
