import instance from '../xhrService';

export interface Tenant {
  id?: number;
  name: string;
  domain: string;
}

export interface Division {
  id: number;
  name: string;
  createdAt?: Date;
}

const tenantApi = {
  index(): Promise<Tenant[]> {
    return instance.get(`/api/tenants`).then((res) => res.data);
  },
  create(tenant: Tenant): Promise<Tenant> {
    return instance.post(`/api/tenants`, tenant).then((res) => res.data);
  },
  update(tenant: Tenant): Promise<Tenant> {
    return instance
      .put(`/api/tenants/${tenant.id}`, tenant)
      .then((res) => res.data);
  },
  delete(tenant: Tenant): Promise<Tenant> {
    return instance.delete(`/api/tenants/${tenant.id}`).then((res) => res.data);
  },
  show(tenant: Tenant): Promise<Tenant> {
    return instance.get(`/api/tenants/${tenant.id}`).then((res) => res.data);
  },

  divisions: {
    index(tenantId: number): Promise<Division[]> {
      return instance
        .get(`/api/tenants/${tenantId}/divisions`)
        .then((res) => res.data);
    },
    getDivisionsForTenants(tenantIds: number[]): Promise<Division[]> {
      return instance
        .get(`/api/tenants/divisions`, {
          params: { tenantIds },
        })
        .then((res) => res.data);
    },
  },
  regulations: {
    index(tenantId: number): Promise<Division[]> {
      return instance
        .get(`/api/tenants/${tenantId}/regulations`)
        .then((res) => res.data);
    },
  },

  features: {
    index(tenantId: number, type?: string): Promise<any> {
      const query = type ? `type=${type}` : '';

      return instance
        .get(`/api/tenants/${tenantId}/features?${query}`)
        .then((res) => res.data);
    },

    update(
      tenantId: number,
      featureId: number,
      isEnabled: boolean,
    ): Promise<any> {
      return instance
        .put(`/api/tenants/${tenantId}/updateTenantFeatures/${featureId}`, {
          is_enabled: isEnabled,
        })
        .then((res) => res.data);
    },

    getTenantFeature(): Promise<any> {
      return instance
        .get('/api/tenants/features')
        .then((res) => res.data)
    }
  },

  whitelist: {
    getCompanyLogo(tenantId: number): Promise<any> {
      return instance.get(`/api/tenants/${tenantId}/getCompanyLogo`).then(response => response.data).catch(e => { console.log(e.message); return Promise.reject(false); });
    },
    uploadTenantLogo: ({ tenantId, formData }: { tenantId: number; formData: any; }): Promise<any> => {
      if (!tenantId || !formData) { return Promise.reject(false) };
      
      return instance.post(`/api/tenants/${tenantId}/uploadTenantLogo`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => response.data).catch(e => { console.log(e.message); return Promise.reject(false); });
    },
    deleteCompanyLogo: (tenantId: number): Promise<any> => {
      return instance.delete(`/api/tenants/${tenantId}/deleteCompanyLogo`).then(response => response.data).catch(e => { console.log(e.message); return Promise.reject(false); });
    }
  }
};

export default tenantApi;
