import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../contexts/AppContext';
import userApi from '../../utils/api/user';
import ElectronicSignatureConsentModal from '../Shared/ElectronicSignatureConsentModal';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import UploadUserSignature from './UploadUserSignature';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import SelectFontSignature from './SelectFontSignature';

const ElectronicSignatureControl: React.FC<{}> = () => {
  const appContext = useContext(AppContext);
  const [isSigned, setIsSigned] = useState<boolean>(
    appContext.get('user.electronicSignatureAuthorization'),
  );
  const animatedComponents = makeAnimated();
  const [showConsentModal, setShowConsentModal] = useState<boolean>(false);
  const [signatureType, setSignatureType] = useState<any>();

  const handleClick = async () => {
    const userId = appContext.get('user.id');
    const action = isSigned ? 'disagree' : 'agree';

    const resp = await userApi.updateElectronicSignatureAuthorization(
      userId,
      action,
    );

    setIsSigned(resp.electronicSignatureAuthorization);
    appContext.set(
      'user.electronicSignatureAuthorization',
      resp.electronicSignatureAuthorization,
    );
  };

  const loadInitialData = () => {
    return userApi
      .getUserSignature()
      .then((userSignatureResponse) => {
        if (userSignatureResponse && userSignatureResponse.success === true) {
          if (
            userSignatureResponse.userSignature.type_of_signature ===
            'uploaded_signature'
          ) {
            setSignatureType({
              value: 'uploadedSignature',
              label: 'Upload Signature',
            });
          } else {
            setSignatureType({ value: 'font', label: 'Font Wise' });
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  return (
    <div className='electronic-signature-control'>
      <h3>Electronic Signature Control</h3>
      <span className='sub-header-wrapper'>
        <span className='sub-header'>
          Consent to and Manage your Electronic Signature
        </span>
      </span>
      <div className='top-border'>
        {isSigned && (
          <>
            <div className='set-select-type-block'>
              <label>Select Signature Type</label>
              <div className='responsive-select-type'>
                <Select
                  classNamePrefix='responsive-select'
                  components={animatedComponents}
                  placeholder='Select Signature Type'
                  isSearchable={false}
                  onChange={(selected) => setSignatureType(selected)}
                  options={[
                    { value: 'uploadedSignature', label: 'Upload Signature' },
                    { value: 'font', label: 'Font Wise' },
                  ]}
                  menuPlacement='auto'
                  value={signatureType}
                />
              </div>
            </div>
            {signatureType?.value === 'uploadedSignature' && (
              <UploadUserSignature />
            )}
            {signatureType?.value === 'font' && <SelectFontSignature />}
          </>
        )}
        <div className='signature-form'>
          <input type='checkbox' checked={isSigned} onChange={handleClick} />
          <p>
            I agree to the use of electronic signatures.{' '}
            <FontAwesomeIcon
              className='modal-icon'
              icon={faQuestionCircle}
              onClick={() => setShowConsentModal(true)}
            />
          </p>
        </div>
      </div>
      {showConsentModal && (
        <ElectronicSignatureConsentModal
          show={showConsentModal}
          handleClose={() => setShowConsentModal(false)}
        />
      )}
    </div>
  );
};

export default ElectronicSignatureControl;
