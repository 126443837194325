import { asSchemaDefinition, SchemaFieldsWithContext } from '@sw-sw/lib-form';
import { UIControlType } from '@sw-sw/lib-form-control-types';
import React, { useContext, useState, useEffect } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import AuthenticationContext from '../../../contexts/AuthenticationContext';
import FormActions from '../../Shared/form/modal/FormActions';
// import { datadogRum } from '@datadog/browser-rum';

const schema = asSchemaDefinition({
  email: {
    label: 'Email',
    className: 'bottom-outline',
    autoComplete: 'username',
    validation: {
      required: true,
      format: 'email',
    },
  },
  password: {
    controlType: UIControlType.password,
    label: 'Password',
    className: 'bottom-outline',
    autoComplete: 'current-password',
    validation: {
      required: true,
    },
  },
});

const LoginForm = () => {
  const authStore = useContext(AuthenticationContext);
  const location = useLocation();

  const urlSearchParams = new URLSearchParams(location.search);


  const [step, setStep] = useState(0);
  const [tenants, setTenants] = useState<any>([]);
  const [tenantInfo, setTenantInfo] = useState<any>(null);

  useEffect(() => {
    if (
      window.location.host.split('.').length > 1 &&
      window.location.origin !== process.env.REACT_APP_LOGIN_URL
    ) {
      const tenantName = window.location.host.split('.')[0];

      getTenant(tenantName);
    }
  }, [window.location.host]);

  const getTenant = async (name: string) => {
    try {
      
      const tenantData = await authStore.getTenantByName(name);

      if (!tenantData) {
        window.location.href = `${process.env.REACT_APP_LOGIN_URL}/login`;
      }
      setTenantInfo(tenantData);
    } catch (error) {
      window.location.href = `${process.env.REACT_APP_LOGIN_URL}/login`;
    }
  };

  const handleVerify = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    if (!tenantInfo) {
      const tenantsData = await authStore.verifyUser(email, password);

      setTenants(tenantsData);
      setStep(1);
    } else {
      await handleLogin({ email, password, tenant: tenantInfo.domain });
    }
  };

  const handleLogin = async ({
    email,
    password,
    tenant,
  }: {
    email: string;
    password: string;
    tenant: string;
  }) => {
    try {
      // datadogRum.addAction('login', {
      //   email,
      //   tenant,
      // });

      const token = await authStore.login(email, password, tenant);
      

      const startUrl = process.env.REACT_APP_CLIENT_BASE_URL;

      if (token && startUrl) {
        const url = startUrl.split('//');

        window.location.href = `${url[0]}//${tenant}.${
          url[1]
        }/identity-auth?token=${token}&to=${urlSearchParams.get('to') || '/'}`;
      }
    } catch (error) {
      throw error;
    }
  };

  if (authStore.loggedIn) {
    return <Redirect to={urlSearchParams.get('to') || '/'} />;
  }

  const schema2 = asSchemaDefinition({
    tenant: {
      label: 'Account',
      controlType: UIControlType.select,
      validation: { required: true },
      options:
        tenants.length &&
        tenants.map((tenantData: any) => ({
          label: tenantData.name,
          value: tenantData.domain,
        })),
      className: 'bottogit pull origin release-multitenancym-outline',
    },
  });

  if (step === 0) {
    return (
      <>
        <form onSubmit={() => handleVerify}>
          <SchemaFieldsWithContext className='login-form' schema={schema}>
            <FormActions
              submitText='Login'
              onSubmit={handleVerify}
              onCancel={() => {}}
              showCancel={false}
              small={false}
              showSubmit={true}
              showErrorText={true}
              errorMessageText='Invalid form data. Please review errors above.'
              cancelText='Cancel'
            >
              <Link to='/forgot' className='no-underline forgot-password'>
                Forgot password
              </Link>
            </FormActions>
          </SchemaFieldsWithContext>
        </form>
      </>
    );
  }
  if (step === 1) {
    return (
      <form onSubmit={() => handleLogin}>
        <SchemaFieldsWithContext className='login-form' schema={schema2}>
          <FormActions
            submitText='Submit'
            onSubmit={handleLogin}
            onCancel={() => {}}
            showCancel={false}
            small={false}
            showSubmit={true}
            showErrorText={true}
            errorMessageText='Invalid form data. Please review errors above.'
            cancelText='Cancel'
          ></FormActions>
        </SchemaFieldsWithContext>
      </form>
    );
  } else {
    return <div></div>;
  }
};

export default LoginForm;
