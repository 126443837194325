import React from 'react';
import { useParams } from 'react-router-dom';
import { ComplianceReport } from './ComplianceReport';
import { ProjectActivityLogReport } from './ProjectActivityLog/ProjectActivityLogReport';
import { FormContextProvider } from '@sw-sw/lib-form';

export default function ReportDetails() {
  const params = useParams() as any;

  const getReport = () => {
    switch (params.slug) {
      case 'Compliance Report':
        return (
          <FormContextProvider>
            <ComplianceReport />
          </FormContextProvider>
        );
      case 'Project Activity Report':
        return <ProjectActivityLogReport />;
      default:
        return <div>Report not found for {params.slug}</div>;
    }
  };

  return <div>{getReport()}</div>;
}
