import instance from "../xhrService";

/**
 * Commentables
 */

const url = (a, b = "", c) => `/api/notes/${a}/${b}` + (c ? `/${c}` : "");

const commentableApi = {
  /**
   * Index commentables on the specified morphable model
   *
   * @param {string} modelType commentable_type
   * @param {number} modelId commentable_id
   */
  index: (modelType, modelId) => {
    return instance.get(url(modelType, modelId)).then(_ => _.data);
  },
  create: (modelType, modelId, data) => {
    return instance.post(url(modelType, modelId), data).then(_ => _.data);
  },
  update: (modelType, modelId, data) => {
    if (!data.id) {
      return Promise.reject(new Error("`data.id` is required"));
    }

    return instance
      .put(url(modelType, data.id), data)
      .then(_ => _.data);
  },
  destroy: (id, modelType, modelId) => {
    return instance.delete(url(modelType, id)).then(() => true);
  },
};

export default commentableApi;
