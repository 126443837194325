import React, { useReducer, useState } from "react";
import {
  get,
  uniq,
  reject,
  without,
  startCase,
  findIndex,
  filter,
} from "lodash";
import pluralize from "pluralize";

const Context = React.createContext();

const defaultState = () => ({
  __data_source_types: [],
});

function reducer(state, action) {
  switch (action.type) {
    case "set":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case "addType":
      return {
        ...state,
        [action.payload.type]: action.payload.data,
        __data_source_types: uniq([
          ...state.__data_source_types,
          action.payload.type,
        ]),
      };

    case "rmType":
      return {
        ...state,
        __data_source_types: uniq(
          without(state.__data_source_types, action.payload),
        ),
        [action.payload]: undefined,
      };

    case "removeArrayItem":
      return {
        ...state,
        [action.payload.key]: reject(state[action.payload.key], {
          id: action.payload.id,
        }),
      };

    case "updateArrayItem":
      const items = state[action.payload.key];
      const itemIndex = findIndex(items, action.payload.filter);

      if (itemIndex > -1) {
        return {
          ...state,
          [action.payload.key]: [
            ...items.slice(0, itemIndex),
            action.payload.data,
            ...items.slice(itemIndex + 1),
          ],
        };
      } else {
        // add item
        return {
          ...state,
          [action.payload.key]: [...items, action.payload.data],
        };
      }

    default:
      throw new Error(`action type ${action.type} doesn't exists in the reducer`);
  }
}

/**
 * Generic keyed data container
 */
function DataSourceContext(props) {
  const [state, dispatch] = useReducer(reducer, defaultState());
  const [undo, setUndo] = useState([])
  const [tempAddedOnDates, setTempAddedOnDates] = useState({})

  const setFn = (key, value) => {
    dispatch({
      type: "set",
      payload: { key, value },
    })
  }

  const contextValue = {
    undo,
    setUndo,
    get: (...path) => (path && path.length ? get(state, path) : state),
    getById: (id, key) => {
      const items = get(state, key);

      if (items && Array.isArray(items)) {
        const filteredItem = items
          .map((item, index) => ({
            /** @important zip with index */
            ...item,
            index,
          }))
          .filter(item => {
            return item.id === id;
          });

        if (filteredItem.length) {
          return filteredItem[0];
        }
      }

      return null;
    },
    filter: (key, predicate) => {
      return filter(get(state, key), predicate);
    },

    //
    set: setFn,
    unset: key => setFn(key, undefined),

    /**
     * add/update data
     */
    updateArrayItem: (key, data, filter) => {
      if (!filter) {
        filter = { id: data.id };
      }

      if (Array.isArray(get(state, key))) {
        dispatch({
          type: "updateArrayItem",
          payload: {
            key,
            data,
            filter,
          },
        });
      }
    },
    // remove an array item by id
    removeArrayItem: (key, id) => {
      if (Array.isArray(get(state, key))) {
        dispatch({
          type: "removeArrayItem",
          payload: {
            key,
            id,
          },
        });
      }
    },

    /**
     * List of item source types, managed manually by context consumer,
     */
    getDataTypes: () => state.__data_source_types,
    addDataType: (type, data) =>
      dispatch({
        type: "addType",
        payload: { data, type },
      }),
    rmDataType: dataSourceType =>
      dispatch({
        type: "rmType",
        payload: dataSourceType,
      }),

    /**
     * Return keys from {state} that are not "data type" models
     */
    getDataInstanceGroups: () =>
      Object.keys(state).reduce((data, key) => {
        if (
          !key.includes("__data_source_type") &&
          -1 === state.__data_source_types.indexOf(key) &&
          state.hasOwnProperty(key) &&
          state[key] !== undefined
        ) {
          data[key] = state[key];
        }

        return data;
      }, {}),

    /** manage label for data type */
    setDataTypeLabel: (type, label) =>
      setFn(`__data_source_type_label_${type}`, label),
    getDataTypeLabel: (type, plural = true) => {
      let v = get(state, `__data_source_type_label_${type}`);

      if (!v && state.__data_source_types.includes(type)) {
        v = type;
      }

      if (!v) {
        throw Error("Unsupported data type " + type);
      }

      v = startCase(v);

      return plural ? pluralize.plural(v) : pluralize.singular(v);
    },

    /** manage positionable type arguments (ie. targetType, targetId) */
    setDataTypeArguments: (type, data) =>
      setFn(`__data_source_type_arguments_${type}`, data),
    getDataTypeArguments: type => {
      if (state.__data_source_types.includes(type)) {
        return get(state, `__data_source_type_arguments_${type}`);
      }
    },
    /** Check the dataTypeArgs.sourceModelFormProps for 'readOnly' */
    isReadOnlyDataType: type => {
      if (state.__data_source_types.includes(type)) {
        return get(
          state,
          `__data_source_type_arguments_${type}.sourceModelFormProps.readOnly`,
          false,
        );
      }

      return false;
    },
    tempAddedOnDates,
    setTempAddedOnDates,
  };

  return (
    <Context.Provider value={contextValue}>{props.children}</Context.Provider>
  );
}

export { Context };

export default DataSourceContext;
